import { useEffect, useState } from 'react';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import CountryComboBox from '../../components/combobox/country-combobox';
import { PrivatePage } from '../../components/private-page';
import {
  AddEnvironmentAsync,
  DeleteEnvironmentAsync,
  GetEnvironmentByIdAsync,
  PatchEnvironmentAsync
} from '../../services/environments';
import { ValidationRules, validator } from 'nm-validator';
import { required } from 'nm-validator/dist/validationRules';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { EnvironmentRequest } from '../../types/environment';
import { YesNoDialogResult } from '../../types/dialog-result';

export interface EnvironmentRequestError {
  name?: string;
  region?: string;
  hostUrl?: string;
}

export interface EnvironmentFieldMapping {
  id?: number;
  name?: string;
  country?: string;
  region?: string;
  hostUrl?: string;
}

const Environment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  //if param is undefined or not contain any valid environment Id, it will be treated as new environment page
  const envId = !id ? 0 : parseInt(id!);
  const isNewEnv = isNaN(envId); // return true if id is not a number

  const [environment, setEnvironment] = useState<EnvironmentRequest>({
    id: 0,
    name: '',
    country: '',
    region: '',
    hostUrl: ''
  });
  const [errors, setErrors] = useState<EnvironmentRequestError>();
  const [openDeleteEnvDialog, setOpenDeleteEnvDialog] = useState(false);

  // fetch environment data function
  const fetchData = async (envId: number) => {
    GetEnvironmentByIdAsync(envId)
      .then((response) => {
        setEnvironment({
          id: response.id,
          name: response.name,
          country: response.country,
          region: response.region,
          hostUrl: response.hostUrl
        });
      })
      .catch((err) => console.error(err));
  };

  // update values on change event
  const onChangeNameEvent = (event: string) => {
    //set name value
    setEnvironment({
      ...environment,
      name: event ?? ''
    });
  };

  const onChangeHosturlEvent = (event: string) => {
    //set name value
    setEnvironment({
      ...environment,
      hostUrl: event ?? ''
    });
  };

  const onChangeCountryEvent = (event: ComboBoxChangeEvent) => {
    //set name value
    setEnvironment({
      ...environment,
      country: event.value?.country ?? '',
      region: event.value?.iso ?? ''
    });
  };

  const createEnvironment = async () => {
    try {
      const validationResult = validate();

      if (!validationResult.isValid) {
        return;
      }

      if (environment) {
        const request = { ...environment };

        // update environment
        if (!isNewEnv) {
          PatchEnvironmentAsync(request.id!, request);
          toastStore.show(
            'Environment',
            <div>Environment saved.</div>,
            'success'
          );
        }

        // create new environment
        if (isNewEnv) {
          await AddEnvironmentAsync(request);
          toastStore.show(
            'Environment',
            <div>Environment saved.</div>,
            'success'
          );
          navigate('/administration/environments');
        }
      }
    } catch (err) {
      toastStore.show(
        'Environment',
        <div>Failed to save environment.</div>,
        'error'
      );
    }
  };

  // validations
  const validate = () => {
    const rules: ValidationRules<EnvironmentFieldMapping> = {
      name: [required()],
      region: [required()],
      hostUrl: [required()]
    };
    const validationResult = validator.validateObject<
      EnvironmentFieldMapping,
      EnvironmentRequestError
    >(environment, rules);
    setErrors(validationResult.errors);
    return validationResult;
  };

  const onBack = () => {
    navigate('/administration/environments/');
  };

  const showDeleteEnvDialog = () => {
    setOpenDeleteEnvDialog(true);
  };

  const closeDeleteEnvDialog = () => {
    setOpenDeleteEnvDialog(false);
  };

  const confirmDeleteEnvDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();
        await DeleteEnvironmentAsync(envId);
        toastStore.show(
          'Environment',
          <div>Environment deleted.</div>,
          'success'
        );
      } catch {
        toastStore.show(
          'Environment',
          <div>Failed to delete environment.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
      closeDeleteEnvDialog();
      navigate('/administration/environments');
    }
    closeDeleteEnvDialog();
  };

  useEffect(() => {
    if (!isNewEnv) {
      fetchData(envId);
    }
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: 'administration' },
        { label: 'Environments', href: 'administration/environments' },
        {
          label: 'Environment details',
          href: `administration/environment/${envId}`
        }
      ]}
      pageTitle={'Administration'}>
      <Container>
        <Row className={'border-bottom pb-4'}>
          <Col md={6} sm={6} className={'d-flex align-items-center'}>
            <h1 className={'m-0'}>Required Information</h1>
          </Col>
          <Col md={6} sm={6}>
            <div className={'d-flex gap-2 justify-content-end'}>
              <Button
                variant={'secondary'}
                className={'text-light'}
                onClick={onBack}>
                Back
              </Button>

              <Button
                variant={'primary'}
                className={'text-light'}
                onClick={createEnvironment}>
                {isNewEnv ? 'Save' : 'Update'}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col lg={4} md={6}>
            <Label>Environment name</Label>
            <Input
              id={'name'}
              maxLength={256}
              value={environment?.name}
              onChange={(e) => {
                onChangeNameEvent(e.value);
              }}
            />
            {errors?.name && <Error>{errors?.name}</Error>}
          </Col>
          <Col lg={4} md={6}>
            <Label>Region</Label>
            <CountryComboBox
              value={{
                iso: environment?.region,
                country: environment?.country
              }}
              clearButton={false}
              onChange={(e) => onChangeCountryEvent(e)}
            />
            {environment?.country === '' && errors?.region && (
              <Error>{errors?.region}</Error>
            )}
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col md={12}>
            <Label>Environment Endpoint URL</Label>
            <Input
              value={environment?.hostUrl}
              onChange={(e) => {
                onChangeHosturlEvent(e.value);
              }}
              maxLength={256}></Input>
            {errors?.hostUrl && <Error>{errors?.hostUrl}</Error>}
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col>
            <span className={'btn btn-link'} onClick={showDeleteEnvDialog}>
              Remove
            </span>
          </Col>
        </Row>
      </Container>

      {openDeleteEnvDialog && (
        <YesNoDialog
          title={'Remove Environment'}
          onConfirm={confirmDeleteEnvDialog}
          onClose={closeDeleteEnvDialog}>
          Are you sure you want to remove this Environment?
        </YesNoDialog>
      )}
    </PrivatePage>
  );
};

export default Environment;
