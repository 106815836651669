import React from 'react';
import {
  GameDocumentContext,
  GameDocumentState
} from '../../contexts/game-document';
import { GameDocument } from '../../types/game-document';
import { RadioButton } from '@progress/kendo-react-inputs';
import CoverImage from '../cover-image';
import PopupMenu from '../popupMenu';
import { MenuList } from '../../features/game-document/maps/map-images-window';
import {
  ResourceWindow,
  UploadedImage
} from '../../features/game-document/image-resource/resource-window';
import {
  AddResourceAsync,
  AllCorrectCustomFeedback,
  AllWrongCustomFeedback,
  GetResourceEntity,
  GetResourceValue,
  MergeResources,
  UpdateResourceAsync
} from '../../utils/game-document';
import { useGameDocumentResources } from '../../hooks/use-game-document-resources';
import YesNoDialog from '../dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../../types/dialog-result';
import { Designer } from '../../constants/settings';
import {
  DefaultAllCorrectFeedbackMessageResource,
  DefaultAllWrongFeedbackMessageResource,
  MergeGameSettings,
  UpdateDesignerSettingAsync
} from '../../utils/game-document/settings';
import { ResourceEntity } from '../../types/game-document/';
import { uuid } from '../../types/common-helper';
import { CustomFeedbackMessage } from '../questionnaire/custom-feedback-message';
import { TextareaInput } from '../form-input';
import { DesignerSettings } from '../../types/game-document/settings';
import { GetDefaultDesignerSettings } from '../../utils/game-document/factory';
import { defaultFeedbackMessage } from '../../constants/feedback-content';

interface DesignerSettingsProps {
  state: GameDocumentState;
  onChange: (gameDocument: GameDocument) => void;
}

const defaultImage = {
  imageResId: 'https://cdn.catalystglobal.games/resources/map-task.png',
  completeImageResId:
    'https://cdn.catalystglobal.games/resources/map-task--complete.png',
  defaultFeedbackAllCorrectIconResId:
    'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/checked.png',
  defaultFeedbackAllWrongIconResId:
    'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/cross.png'
};

const DesignerSetting: React.FC<DesignerSettingsProps> = ({
  state,
  onChange
}) => {
  const resourceFiles = useGameDocumentResources();
  const [dialogUploadVisible, setDialogUploadVisible] =
    React.useState<boolean>(false);

  const [
    dialogTaskContentIconUploadVisible,
    setDialogTaskContentIconUploadVisible
  ] = React.useState<boolean>(false);

  const [resourceType, setResourceType] = React.useState<
    'imageResId' | 'completeImageResId'
  >('imageResId');

  const [taskContentresourceType, setTaskContentresourceType] = React.useState<
    'defaultFeedbackAllCorrectIconResId' | 'defaultFeedbackAllWrongIconResId'
  >('defaultFeedbackAllCorrectIconResId');

  const [
    showDeleteTaskContentIconConfirm,
    setShowDeleteTaskContentIconConfirm
  ] = React.useState<boolean>(false);

  const [showDeleteIconConfirm, setShowDeleteIconConfirm] =
    React.useState<boolean>(false);

  const handleDesignerSettingChange = (
    field:
      | 'taskCompletionVisibility'
      | 'taskAvailableIcon'
      | 'taskCompletedIcon',
    value: string | boolean
  ) => {
    let designerSetting = state.gameDocument?.settings.designer!;
    UpdateDesignerSettingAsync(state.gameDocument!, {
      ...designerSetting,
      [field]: value
    }).then((updatedGameDocument) => {
      onChange(updatedGameDocument);
    });
  };

  const handleChangeTasksImageResource = React.useCallback(
    (file: UploadedImage) => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const { defaultTaskAvailableIconResId, defaultTaskCompletedIconResId } =
          state.gameDocument.settings.designer;
        resourceFiles.updateResourceFile(
          resourceType === 'imageResId'
            ? defaultTaskAvailableIconResId
            : defaultTaskCompletedIconResId,
          file
        );
      }
    },
    [state.gameDocument, resourceType, resourceFiles]
  );

  const handleChangeTaskContentsImageResource = React.useCallback(
    (file: UploadedImage) => {
      if (state.gameDocument && state.gameDocument.assets.taskContents) {
        let designer = state.gameDocument.settings.designer;
        let resultId = designer[taskContentresourceType];
        if (resultId) {
          resourceFiles.updateResourceFile(resultId, file);

          return;
        }

        resultId = uuid();
        resourceFiles.addResourceFile(resultId, file, (response) => {
          designer[taskContentresourceType] = resultId;
          MergeGameSettings(state.gameDocument!, designer, 'designer');

          onChange(state.gameDocument!);
        });
      }
    },
    [state.gameDocument, resourceType, resourceFiles]
  );

  const toggleUploadDialog = () => setDialogUploadVisible(!dialogUploadVisible);

  const toggleUploadTaskContentDialog = () =>
    setDialogTaskContentIconUploadVisible(!dialogTaskContentIconUploadVisible);

  const onConfirmDeleteIcon = (result: YesNoDialogResult) => {
    if (
      result === 'yes' &&
      state.gameDocument &&
      state.gameDocument?.settings &&
      state.gameDocument?.settings.designer
    ) {
      const { defaultTaskAvailableIconResId, defaultTaskCompletedIconResId } =
        state.gameDocument.settings.designer;
      const resources = state.gameDocument.resources;
      let resource: ResourceEntity | undefined;

      if (resourceType === 'imageResId') {
        resource = resources.find(
          (res) => res.id === defaultTaskAvailableIconResId
        );
      } else {
        resource = resources.find(
          (res) => res.id === defaultTaskCompletedIconResId
        );
      }

      if (resource) {
        resource.value =
          resourceType === 'imageResId'
            ? defaultImage.imageResId
            : defaultImage.completeImageResId;
        UpdateResourceAsync(state.gameDocument, resource.id, resource).then(
          (response) => onChange(response)
        );
      }
    }
    setShowDeleteIconConfirm(false);
  };

  const onConfirmDeleteTaskContentIcon = (result: YesNoDialogResult) => {
    if (
      result === 'yes' &&
      state.gameDocument &&
      state.gameDocument?.settings &&
      state.gameDocument?.settings.designer
    ) {
      const {
        defaultFeedbackAllCorrectIconResId,
        defaultFeedbackAllWrongIconResId
      } = state.gameDocument.settings.designer;
      const resources = state.gameDocument.resources;
      let resource: ResourceEntity | undefined;

      if (taskContentresourceType === 'defaultFeedbackAllCorrectIconResId') {
        resource = resources.find(
          (res) => res.id === defaultFeedbackAllCorrectIconResId
        );
      } else {
        resource = resources.find(
          (res) => res.id === defaultFeedbackAllWrongIconResId
        );
      }

      if (resource) {
        resource.value =
          taskContentresourceType === 'defaultFeedbackAllCorrectIconResId'
            ? defaultImage.defaultFeedbackAllCorrectIconResId
            : defaultImage.defaultFeedbackAllWrongIconResId;
        UpdateResourceAsync(state.gameDocument, resource.id, resource).then(
          (response) => onChange(response)
        );
      }
    }
    setShowDeleteTaskContentIconConfirm(false);
  };

  const taskMenu: MenuList[] = [
    { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
    { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
  ];

  const handleEditorSubmit = (uploadedFile: UploadedImage) => {
    setDialogUploadVisible(false);
    if (state.gameDocument && state.gameDocument.assets.tasks) {
      handleChangeTasksImageResource(uploadedFile);
    }
  };

  const handleTaskContentEditorSubmit = (uploadedFile: UploadedImage) => {
    setDialogTaskContentIconUploadVisible(false);
    if (state.gameDocument && state.gameDocument.assets.taskContents) {
      handleChangeTaskContentsImageResource(uploadedFile);
    }
  };

  const handleTaskIconSelected =
    (field: 'imageResId' | 'completeImageResId') =>
    (id: number, menu: MenuList) => {
      setResourceType(field);
      if (menu.textMenu === 'Edit') setDialogUploadVisible(true);
      if (menu.textMenu === 'Delete') setShowDeleteIconConfirm(true);
    };

  const handleTaskContentIconSelected =
    (
      field:
        | 'defaultFeedbackAllCorrectIconResId'
        | 'defaultFeedbackAllWrongIconResId'
    ) =>
    (id: number, menu: MenuList) => {
      setTaskContentresourceType(field);
      if (menu.textMenu === 'Edit') setDialogTaskContentIconUploadVisible(true);
      if (menu.textMenu === 'Delete') setShowDeleteTaskContentIconConfirm(true);
    };

  const imageUrl = React.useCallback(
    (res: 'imageResId' | 'completeImageResId') => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const availableTaskIcon = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer.defaultTaskAvailableIconResId
        );
        const completeTaskIcon = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer.defaultTaskCompletedIconResId
        );
        return res === 'imageResId' ? availableTaskIcon : completeTaskIcon;
      } else {
        return res === 'imageResId'
          ? defaultImage.imageResId
          : defaultImage.completeImageResId;
      }
    },
    [state.gameDocument?.settings]
  );

  const taskContentImageUrl = React.useCallback(
    (
      res:
        | 'defaultFeedbackAllCorrectIconResId'
        | 'defaultFeedbackAllWrongIconResId'
    ) => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const feedbackIcon = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer[res]
        );

        return feedbackIcon !== '' ? feedbackIcon : defaultImage[res];
      } else {
        return res === 'defaultFeedbackAllCorrectIconResId'
          ? defaultImage.defaultFeedbackAllCorrectIconResId
          : defaultImage.defaultFeedbackAllWrongIconResId;
      }
    },
    [state.gameDocument?.settings]
  );

  const defaultMessage = React.useCallback(
    (
      type:
        | 'defaultAllCorrectFeedbackMessageResId'
        | 'defaultAllWrongFeedbackMessageResId'
    ) => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const message = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer[type] ?? ''
        );

        const value = message == '' ? defaultFeedbackMessage[type] : message;
        return value;
      }

      return defaultFeedbackMessage[type];
    },
    [state.gameDocument?.settings]
  );

  const AddNewDefaultMessage = (
    type:
      | 'defaultAllCorrectFeedbackMessageResId'
      | 'defaultAllWrongFeedbackMessageResId',
    value: string | undefined,
    designer: DesignerSettings
  ) => {
    if (type == 'defaultAllCorrectFeedbackMessageResId') {
      DefaultAllCorrectFeedbackMessageResource(
        state.gameDocument!,
        value!
      ).then((id) => {
        designer[type] = id;
        MergeGameSettings(state.gameDocument!, designer, 'designer');

        onChange(state.gameDocument!);
      });
    } else {
      DefaultAllWrongFeedbackMessageResource(state.gameDocument!, value!).then(
        (id) => {
          designer[type] = id;
          MergeGameSettings(state.gameDocument!, designer, 'designer');

          onChange(state.gameDocument!);
        }
      );
    }
  };

  const onChangeDefaultFeedbackMessage = React.useCallback(
    (
      type:
        | 'defaultAllCorrectFeedbackMessageResId'
        | 'defaultAllWrongFeedbackMessageResId',
      value: string | undefined
    ) => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        let designer = state.gameDocument.settings.designer;
        const resourceId = state.gameDocument.settings.designer[type];

        if (resourceId) {
          let resource = GetResourceEntity(
            state.gameDocument,
            state.gameDocument.settings.designer[type]
          );

          if (!resource) {
            AddResourceAsync(
              state.gameDocument,
              type == 'defaultAllCorrectFeedbackMessageResId'
                ? 'All correct feedback message default'
                : 'All wrong feedback message default',
              type == 'defaultAllCorrectFeedbackMessageResId'
                ? 'All correct feedback message default'
                : 'All wrong feedback message default',
              'text',
              value!,
              state.gameDocument.settings.designer[type]
            ).then((response) => {
              onChange(response);
            });
            return;
          }

          const updatedResource = { ...resource, value: value };

          UpdateResourceAsync(
            state.gameDocument,
            resourceId,
            updatedResource
          ).then((response) => {
            onChange(response);
          });

          return;
        }

        AddNewDefaultMessage(type, value, designer);
      } else {
        let designer: DesignerSettings = GetDefaultDesignerSettings();
        AddNewDefaultMessage(type, value, designer);
      }
    },
    [state.gameDocument?.settings]
  );

  return (
    <>
      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Completed Task Visibility</span>
        <RadioButton
          name={Designer.allTasksCompletion}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.designer?.taskCompletionVisibility
          }
          onChange={(e) =>
            handleDesignerSettingChange('taskCompletionVisibility', e.value)
          }
        />

        <RadioButton
          name={Designer.allTasksCompletion}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument.settings.designer?.taskCompletionVisibility
          }
          onChange={(e) =>
            handleDesignerSettingChange('taskCompletionVisibility', e.value)
          }
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Task visibility on completion
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-start mt-2'}>
        <div className="d-flex flex-column">
          <span className={'mr-10 w-30'}>Available Icon</span>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            Sets default available icon for all tasks in the game
          </span>
        </div>

        <CoverImage containerStyle={'square'} imageUrl={imageUrl('imageResId')}>
          <PopupMenu
            id={0}
            menus={taskMenu}
            onMenuSelected={handleTaskIconSelected('imageResId')}
          />
        </CoverImage>
      </div>

      {state.gameDocument?.settings.designer &&
        state.gameDocument.settings.designer.taskCompletionVisibility && (
          <div className={'d-flex gap-1 align-items-start mt-2'}>
            <div className="d-flex flex-column">
              <span className={'mr-10 w-30'}>Complete Icon</span>
              <span className={'mb-2 fs-6 text-black-50'}>
                <span className={'material-symbols-outlined fs-6'}>
                  lightbulb
                </span>
                Sets default complete icon for all tasks in the game
              </span>
            </div>

            <CoverImage
              containerStyle={'square'}
              imageUrl={imageUrl('completeImageResId')}>
              <PopupMenu
                id={0}
                menus={taskMenu}
                onMenuSelected={handleTaskIconSelected('completeImageResId')}
              />
            </CoverImage>
          </div>
        )}

      <div
        className={'d-flex flex-column gap-1 align-items-start mt-2 mb-2'}
        style={{ borderBottom: '1px solid #dee2e6' }}>
        <h2>Default Feedback</h2>
      </div>

      <div className={'d-flex gap-1 align-items-start mt-2'}>
        <div className="d-flex flex-column">
          <span className={'mr-10 w-30'}>Default Feedback Icon - Correct </span>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            Sets the correct default feedback icon for all tasks in the game.
          </span>
        </div>

        <CoverImage
          containerStyle={'square'}
          imageUrl={taskContentImageUrl('defaultFeedbackAllCorrectIconResId')}>
          <PopupMenu
            id={0}
            menus={taskMenu}
            onMenuSelected={handleTaskContentIconSelected(
              'defaultFeedbackAllCorrectIconResId'
            )}
          />
        </CoverImage>
      </div>

      <div className={'d-flex gap-1 align-items-start mt-2'}>
        <div className="d-flex flex-column">
          <span className={'mr-10 w-30'}>
            Default Feedback Icon - Incorrect{' '}
          </span>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            Sets the incorrect default feedback icon for all tasks in the game.
          </span>
        </div>

        <CoverImage
          containerStyle={'square'}
          imageUrl={taskContentImageUrl('defaultFeedbackAllWrongIconResId')}>
          <PopupMenu
            id={0}
            menus={taskMenu}
            onMenuSelected={handleTaskContentIconSelected(
              'defaultFeedbackAllWrongIconResId'
            )}
          />
        </CoverImage>
      </div>

      <div className={'d-flex gap-1 align-items-start mt-2'}>
        <div className="d-flex flex-column">
          <span className={'mr-10 w-30'}>
            Default Feedback Message - Correct{' '}
          </span>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            Sets the default correct feedback text for all tasks in the game.
          </span>
        </div>

        <div className={'mt-3 w-full'}>
          <TextareaInput
            value={defaultMessage('defaultAllCorrectFeedbackMessageResId')}
            className={'w-full'}
            label={'Custom message'}
            name={'custom-message'}
            maxLength={300}
            rows={3}
            onChange={(e) => {
              onChangeDefaultFeedbackMessage(
                'defaultAllCorrectFeedbackMessageResId',
                e.target.value
              );
            }}
          />
        </div>
      </div>

      <div className={'d-flex gap-1 align-items-start mt-2'}>
        <div className="d-flex flex-column">
          <span className={'mr-10 w-30'}>
            Default Feedback Message- Incorrect{' '}
          </span>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            Sets the default incorrect feedback text for all tasks in the game.
          </span>
        </div>
        <div className={'mt-3 w-full'}>
          <TextareaInput
            value={defaultMessage('defaultAllWrongFeedbackMessageResId')}
            className={'w-full'}
            label={'Custom message'}
            name={'custom-message'}
            maxLength={300}
            rows={3}
            onChange={(e) => {
              onChangeDefaultFeedbackMessage(
                'defaultAllWrongFeedbackMessageResId',
                e.target.value
              );
            }}
          />
        </div>
      </div>

      {dialogUploadVisible && (
        <ResourceWindow
          toggleDialog={toggleUploadDialog}
          onSubmit={(uploadedFile) => handleEditorSubmit(uploadedFile)}
          acceptedExtension={'image/*'}
          imageSource={
            resourceType === 'imageResId'
              ? 'Game Design | Assets - Task - Available Icon'
              : resourceType === 'completeImageResId'
                ? 'Game Design | Assets - Task - Complete Icon'
                : undefined
          }
        />
      )}

      {dialogTaskContentIconUploadVisible && (
        <ResourceWindow
          toggleDialog={toggleUploadTaskContentDialog}
          onSubmit={(uploadedFile) =>
            handleTaskContentEditorSubmit(uploadedFile)
          }
          acceptedExtension={'image/*'}
          imageSource={
            taskContentresourceType === 'defaultFeedbackAllCorrectIconResId'
              ? 'Game Design | Assets - Task Content - Feedback Content - All Correct Icon'
              : taskContentresourceType === 'defaultFeedbackAllWrongIconResId'
                ? 'Game Design | Assets - Task Content - Feedback Content - All Wrong Icon'
                : undefined
          }
        />
      )}

      {showDeleteIconConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={(result) => onConfirmDeleteIcon(result)}
          onClose={() => setShowDeleteIconConfirm(false)}>
          Are you sure you want to remove the task icon?
        </YesNoDialog>
      )}

      {showDeleteTaskContentIconConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={(result) => onConfirmDeleteTaskContentIcon(result)}
          onClose={() => setShowDeleteTaskContentIconConfirm(false)}>
          Are you sure you want to remove the task content feedback icon?
        </YesNoDialog>
      )}
    </>
  );
};

export default DesignerSetting;
