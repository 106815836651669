import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Container, Row } from 'react-bootstrap';
import { StandardInput } from '../components/forms';
import { PrivatePage } from '../components/private-page';
import { TextareaInput } from '../components/form-input';
import {
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { appStore } from '../stores/app-store';
import { GameResponse } from '../types/responses/game-response';
import { AddGamesAsync } from '../services/games';
import { GameDocumentVersionResponse } from '../types/responses/game-document-version-response';
import {
  AddGameDocumentAsync,
  AddGameDocumentContentAsync
} from '../services/json-document';
import { GenerateGameDocument } from '../utils/game-document/factory';
import { ExtractBlobResourcesAsync } from '../utils/game-document';
import { toastStore } from '../stores/toast-store';
import { uuid } from '../types/common-helper';
import CoverImage from '../components/cover-image';
import { Label } from '@progress/kendo-react-labels';
import { useAuth } from 'react-oidc-context';
import Toolbar from '../components/page-toolbar';
import { Coordinate } from '../types/place';
import {
  ResourceWindow,
  UploadedImage
} from '../features/game-document/image-resource/resource-window';
import { FieldWrapper } from '@progress/kendo-react-form';
import LanguageComboBox from '../components/combobox/language-combobox';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import HelpSupport from '../components/help-support';
import { formatDate } from '@progress/kendo-intl';
import {
  DisplayLanguagePublished,
  GetDisplayLanguagePublished
} from '../services/admin-display-language';

interface NewGame {
  title: string;
  name: string;
  shortDescrption: string;
  imageHeader: UploadedImage | undefined;
  imageLogo: UploadedImage | undefined;
  isValid: boolean;
  languages: string;
  displayLanguages: string;
}

interface RequiredInput {
  name: string;
  errorMessage: string;
  minLength?: number;
  maxLength?: number;
  isError: boolean;
}

const MyLibraryNew = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [newGame, setNewGame] = useState<NewGame>({
    title: '',
    name: '',
    shortDescrption: '',
    imageHeader: undefined,
    imageLogo: undefined,
    isValid: false,
    languages: '',
    displayLanguages: ''
  });
  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<'Background' | 'Logo'>();
  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    { name: 'Name', errorMessage: 'Input Name is required', isError: true },
    { name: 'Title', errorMessage: 'Input Title is required', isError: true },
    {
      name: 'Short description',
      errorMessage: 'Input Short Description is required',
      maxLength: 256,
      isError: true
    },
    {
      name: 'languages',
      errorMessage: 'Input Language is required',
      isError: true
    },
    {
      name: 'displayLanguages',
      errorMessage: 'Input Display Language is required',
      isError: true
    }
  ]);

  const [displayLanguages, setDisplayLanguages] = useState<
    DisplayLanguagePublished[]
  >([]);

  const onEditBackground = () => {
    setFileUpload('Background');
    setSelectedImageUrl(newGame.imageHeader?.blobUrl ?? '');
    setOpenFileDialog(true);
  };

  const onEditLogo = () => {
    setFileUpload('Logo');
    setSelectedImageUrl(newGame.imageLogo?.blobUrl ?? '');
    setOpenFileDialog(true);
  };

  const handleEntityEditorSubmit = (
    uploadedImage: UploadedImage,
    target: 'Background' | 'Logo'
  ) => {
    if (target === 'Background') {
      setNewGame((prev) => ({ ...prev, imageHeader: uploadedImage }));
    } else {
      setNewGame((prev) => ({ ...prev, imageLogo: uploadedImage }));
    }

    if (openFileDialog) {
      setOpenFileDialog(false);
    }
  };

  const validateInput = (name?: string, value?: string | number | string[]) => {
    if (isEmpty(name)) {
      const requiredItems = [...requiredInputs];
      if (isEmpty(newGame.title)) {
        const index = requiredItems.findIndex((o) => o.name === 'Title');
        requiredItems[index].isError = true;
      }
      if (isEmpty(newGame.shortDescrption)) {
        const index = requiredItems.findIndex(
          (o) => o.name === 'Short description'
        );
        requiredItems[index].isError = true;
      }
      if (isEmpty(newGame.languages)) {
        const index = requiredItems.findIndex((o) => o.name === 'languages');
        requiredItems[index].isError = true;
      }
      if (isEmpty(newGame.displayLanguages)) {
        const index = requiredItems.findIndex(
          (o) => o.name === 'displayLanguages'
        );
        requiredItems[index].isError = true;
      }
      setRequiredInputs(requiredItems);
    }
    setRequiredInputs(
      requiredInputs.map((item) =>
        item.name === name
          ? {
              ...item,
              isError: isEmpty(value)
            }
          : item
      )
    );
  };

  const handleTextChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    validateInput(name, value);
    if (event.target.name === 'Name') {
      setNewGame({
        ...newGame,
        name: value as string,
        isValid:
          !isEmpty(newGame.shortDescrption) && !isEmpty(value) ? true : false
      });
    } else if (event.target.name === 'Title') {
      setNewGame({
        ...newGame,
        title: value as string,
        isValid:
          !isEmpty(newGame.shortDescrption) && !isEmpty(value) ? true : false
      });
    }
  };

  const handleTextAreaChange = (event: TextAreaChangeEvent) => {
    const { name, value } = event.target;
    validateInput(name!, value);
    setNewGame({
      ...newGame,
      shortDescrption: value as string,
      isValid: !isEmpty(value) && !isEmpty(newGame.title) ? true : false
    });
  };

  const mapStateToGameDocument = async (state: NewGame) => {
    let newGameDocument = await GenerateGameDocument();
    newGameDocument.name = state.name;
    newGameDocument.description = state.shortDescrption;
    newGameDocument.author = `${auth.user?.profile.name ?? ''} <${
      auth.user?.profile.email ?? ''
    }>`;
    newGameDocument.resources[0].value = state.title;
    newGameDocument.resources[1].value = state.shortDescrption;
    newGameDocument.resources[2].value = state.shortDescrption;
    if (!isEmpty(state.imageHeader)) {
      const headerResId = uuid();
      const headerResources = {
        id: headerResId,
        name: headerResId,
        description: state.imageHeader.fileName,
        value: state.imageHeader.blobUrl,
        type: 'image',
        size: state.imageHeader.size
      };
      newGameDocument.overview!.headerResId = headerResId;
      newGameDocument.resources.push(headerResources);
    }
    if (!isEmpty(state.imageLogo)) {
      const logoResId = uuid();
      const logoResources = {
        id: logoResId,
        name: logoResId,
        description: state.imageLogo.fileName,
        value: state.imageLogo.blobUrl,
        type: 'image',
        size: state.imageLogo.size
      };
      newGameDocument.overview!.logoResId = logoResId;
      newGameDocument.resources.push(logoResources);
    }

    if (!isEmpty(coordinate)) {
      newGameDocument.assets.maps![0].latitude = coordinate.lat;
      newGameDocument.assets.maps![0].longitude = coordinate.lng;
      newGameDocument.assets.maps![0].url = `https://www.openstreetmap.org/#map=17/${coordinate.lat}/${coordinate.lng}`;
    }

    newGameDocument.overview!.languages = state.languages;

    return newGameDocument;
  };

  const onSaveHandler = async () => {
    if (
      requiredInputs.some(function (value) {
        return value.isError === true;
      })
    ) {
      return;
    }

    try {
      appStore.showLoading();
      const gameRequest = {
        id: 0,
        type: 'game',
        source: 'created',
        status: 'new',
        name: newGame.name,
        shortDescription: newGame.shortDescrption,
        longDescription: newGame.shortDescrption,
        languages: newGame.languages
      } as GameResponse;

      const gameResponse = await AddGamesAsync(gameRequest);
      if (gameResponse && gameResponse!.id! > 0) {
        const dateString = formatDate(new Date(), 'yyMMdd');
        const fileNamePrefix = `${gameResponse!.id}-${dateString}-0.0.1`;
        const gameDocumentRequest = {
          gameId: gameResponse!.id,
          status: 'new',
          version: '0.0.1',
          fileName: `${fileNamePrefix}.json`
        } as GameDocumentVersionResponse;

        const gameDocumentVersionResponse = await AddGameDocumentAsync(
          gameResponse!.id!,
          gameDocumentRequest
        );

        if (
          gameDocumentVersionResponse &&
          gameDocumentVersionResponse!.id! > 0
        ) {
          const gameDocument = await mapStateToGameDocument(newGame);

          let updateGameDocByExtractBlob = await ExtractBlobResourcesAsync(
            fileNamePrefix,
            gameDocument
          );
          updateGameDocByExtractBlob.version =
            gameDocumentVersionResponse.version!;
          updateGameDocByExtractBlob!.overview!.displayLanguages =
            newGame.displayLanguages;
          updateGameDocByExtractBlob!.overview!.displayLanguagesUrl =
            displayLanguages.find(
              (i) => i.languageName === newGame.displayLanguages
            )?.blobFileUrl;

          await AddGameDocumentContentAsync(
            gameResponse!.id!,
            gameDocumentVersionResponse!.id!,
            updateGameDocByExtractBlob
          );

          navigate(`../../designer/${+gameResponse!.id!.toString()}`);
        }
      }
    } catch (ex) {
      toastStore.show('My library', <div>Failed to save game.</div>, 'error');
    } finally {
      appStore.hideLoading();
    }
  };

  const onCancelHandler = () => {
    navigate('/mylibrary/games');
  };

  const toggleEntityEditor = () => setOpenFileDialog(!openFileDialog);

  // events for the input fields
  const onUpdateLocalisations = (event: ComboBoxChangeEvent) => {
    const { name, value } = event.target as {
      name: keyof NewGame;
      value: string;
    };
    validateInput(name as string, value);
    setNewGame({
      ...newGame,
      [name]: value,
      isValid: !isEmpty(value) && !isEmpty(newGame[name]) ? true : false
    });
  };

  const getDisplayLanguages = async () => {
    const response = await GetDisplayLanguagePublished();
    setDisplayLanguages(response.data);
  };

  useEffect(() => {
    function getBrowserCoordinate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          setCoordinate({
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          });
        });
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    }
    getDisplayLanguages();
    getBrowserCoordinate();
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: '../dashboard' },
        { label: 'My Library', href: '../mylibrary/games' },
        { label: 'New Game', href: '../new' }
      ]}
      pageTitle={'My library'}>
      <>
        <Toolbar title={'New game'}>
          <HelpSupport
            title="My Library - Creating, Copying + Editing"
            url="https://forum.catalystglobal.com/t/my-library-games-new"
          />
          <Button
            themeColor={'secondary'}
            onClick={onCancelHandler}
            className={'me-1'}>
            Back
          </Button>
          <Button themeColor={'primary'} onClick={onSaveHandler}>
            Create
          </Button>
        </Toolbar>
        <hr />
        <Container>
          <Row>
            <Col xs={12}>
              <Label>Cover image</Label>
              <CoverImage
                imageUrl={newGame.imageHeader?.blobUrl}
                onDropFiles={(uploadedImage) => {
                  handleEntityEditorSubmit(uploadedImage, 'Background');
                }}>
                <Button fillMode={'flat'} onClick={onEditBackground}>
                  <span className={'material-symbols-outlined'}>more_vert</span>
                </Button>
              </CoverImage>
            </Col>
          </Row>
          <Row className={'mt-2'}>
            <Col xs={'auto'}>
              <CoverImage
                imageUrl={newGame.imageLogo?.blobUrl}
                containerStyle={'rounded'}
                onDropFiles={(uploadedImage) => {
                  handleEntityEditorSubmit(uploadedImage, 'Logo');
                }}>
                <Button fillMode={'flat'} onClick={onEditLogo} rounded={'full'}>
                  <span className={'material-symbols-outlined'}>more_vert</span>
                </Button>
              </CoverImage>
            </Col>
            <Col>
              <Row className={'gy-2'}>
                <StandardInput
                  label={'Name'}
                  name={'Name'}
                  value={newGame.name}
                  onChange={handleTextChange}
                  validationMessage={
                    requiredInputs?.find(
                      (item) => item.name === 'Name' && item.isError
                    )?.errorMessage
                  }
                  maxLength={256}
                />
                <StandardInput
                  label={'Title'}
                  name={'Title'}
                  value={newGame.title}
                  onChange={handleTextChange}
                  validationMessage={
                    requiredInputs?.find(
                      (item) => item.name === 'Title' && item.isError
                    )?.errorMessage
                  }
                  maxLength={256}
                />
                <Col>
                  <TextareaInput
                    label={'Short description'}
                    name={'Short description'}
                    value={newGame.shortDescrption}
                    onChange={handleTextAreaChange}
                    rows={4}
                    maxLength={256}
                    error={
                      requiredInputs?.find(
                        (item) =>
                          item.name === 'Short description' && item.isError
                      )?.errorMessage
                    }
                  />
                </Col>
              </Row>
              <Row className={'mt-2'}>
                <Col>
                  <FieldWrapper>
                    <Label>Language</Label>
                    <LanguageComboBox
                      name={'languages'}
                      onChange={(e) => onUpdateLocalisations(e)}
                      clearButton={false}
                      value={newGame?.languages}
                      validationMessage={
                        requiredInputs?.find(
                          (item) => item.name === 'languages' && item.isError
                        )?.errorMessage
                      }
                    />
                  </FieldWrapper>
                </Col>
                <Col>
                  <FieldWrapper>
                    <Label>Display Language</Label>
                    <LanguageComboBox
                      isDisplayLanguages={true}
                      name={'displayLanguages'}
                      onChange={(e) => onUpdateLocalisations(e)}
                      clearButton={false}
                      value={newGame?.displayLanguages}
                      validationMessage={
                        requiredInputs?.find(
                          (item) =>
                            item.name === 'displayLanguages' && item.isError
                        )?.errorMessage
                      }
                    />
                  </FieldWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {openFileDialog && (
          <ResourceWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={(e) => handleEntityEditorSubmit(e, fileUpload!)}
            acceptedExtension={'image/*'}
            imageUrl={selectedImageUrl}
            hideResourcesTab={true}
            imageSource={
              fileUpload === 'Background'
                ? 'Game Design | Overview - Banner'
                : fileUpload === 'Logo'
                ? 'Game Design | Overview - Game Icon'
                : undefined
            }
          />
        )}
      </>
    </PrivatePage>
  );
};

export default MyLibraryNew;
