export enum Permission {
  'Organisation' = 'My Organisation',
  'Organisation.Info' = 'Information',
  'Organisation.Members' = 'Members',
  'Organisation.DisplayLanguage' = 'Display Language',
  'Network' = 'My Network',
  'Network.Organisations' = 'Organisations',
  'Network.Members' = 'Members',
  'Events' = 'My Events',
  'MyLibrary' = 'My Library',
  'MyLibrary.Assessments' = 'Assessments',
  'MyLibrary.Feedback' = 'Feedback',
  'MyLibrary.Games' = 'Games',
  'GlobalLibrary' = 'Global Library',
  'GlobalLibrary.Assessments' = 'Global Assessments',
  'GlobalLibrary.Feedback' = 'Global Feedback',
  'GlobalLibrary.Games' = 'Global Games',
  'Reports' = 'Reports',
  'Admin' = 'Admin',
  'Admin.Users' = 'All Users',
  'Admin.Organisations' = 'Organisations',
  'Admin.Environments' = 'Environments',
  'Admin.PendingApproval' = 'Pending Approval',
  'Admin.PendingApproval.Assessments' = 'Assessments',
  'Admin.PendingApproval.Feedback' = 'Feedback',
  'Admin.PendingApproval.Games' = 'Games',
  'Admin.SupportTickets' = 'Support Tickets',
  'Admin.Languages' = 'Languages',
  'App.N4S' = 'Need 4 Speed',
  'Admin.Events' = 'Events'
}

export type PermissionType = keyof typeof Permission;
