export const createCustomTaskPosition = ({
  id,
  name,
  iconUrl,
  isAddTaskMode,
  showTasknameVisibility
}: {
  id: string;
  name: string;
  iconUrl: string;
  isAddTaskMode: boolean;
  showTasknameVisibility?: boolean;
}) => {
  const taskContainer = document.createElement('div');
  taskContainer.classList.add('task-container');
  !isAddTaskMode && taskContainer.classList.add('expanded');
  taskContainer.id = `taskContainer-${id}`;

  const iconContainer = document.createElement('div');
  iconContainer.classList.add('icon-container');
  const iconCircle = document.createElement('div');
  iconCircle.classList.add('icon-circle');

  const icon = document.createElement('img');
  icon.id = `icon-${id}`;
  icon.src = iconUrl;
  icon.alt = 'icon';
  icon.draggable = false;
  icon.classList.add('icon-img');
  iconCircle.append(icon);
  iconContainer.append(iconCircle);

  const detailTaskContainer = document.createElement('div');
  detailTaskContainer.classList.add('detail-task-container');
  detailTaskContainer.id = `detailTaskContainer-${id}`;

  const taskName = document.createElement('span');
  taskName.classList.add('d-none', 'fw-bold');
  taskName.id = `taskName-${id}`;
  taskName.innerHTML = name;

  detailTaskContainer.append(taskName);

  if (showTasknameVisibility !== undefined) {
    taskContainer.classList.remove('expanded');
    detailTaskContainer.classList.add('hidden');
    if (!showTasknameVisibility || isAddTaskMode) {
      taskName.classList.add('d-none');
    } else {
      taskName.classList.remove('d-none');
      taskContainer.classList.add('expanded');
      detailTaskContainer.classList.add('visible');
      detailTaskContainer.classList.remove('hidden');
      // taskContainer.addEventListener('mouseover', () => {
      //   taskName.classList.remove('d-none');
      //   taskContainer.classList.add('expanded');
      //   detailTaskContainer.classList.add('visible');
      //   detailTaskContainer.classList.remove('hidden');
      // });
      // taskContainer.addEventListener('mouseout', () => {
      //   taskName.classList.add('d-none');
      //   if (taskDistance.classList.contains('d-none')) {
      //     taskContainer.classList.remove('expanded');
      //     detailTaskContainer.classList.remove('visible');
      //     detailTaskContainer.classList.add('hidden');
      //   }
      // });
    }
  }

  taskContainer.append(iconContainer, detailTaskContainer);
  return taskContainer;
};
