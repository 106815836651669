import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import { useNavigate } from 'react-router-dom';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { toastStore } from '../stores/toast-store';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { GlobalLibraryCardView } from './global-library-cardview';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { CopyGameAsync, GetGlobalGamesAsync } from '../services/games';
import { appStore } from '../stores/app-store';
import { ActionCell, ActionsCell } from '../components/grid/actions-cell';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const GlobalLibraryGrid = () => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [libraries, setLibraries] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchLibrary = async () => {
    const compositeDescriptor = `${toDataSourceRequestString(dataState)}`;

    GetGlobalGamesAsync(compositeDescriptor)
      .then((response) => {
        setLibraries(response);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    fetchLibrary();
  }, [dataState]);

  const onColumnTextClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {' '}
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const onAddCustomAction = (props: GridCellProps) => (
    <AddCustomAction {...props} />
  );

  const onViewLibrary = (id: number) => {
    navigate('/global-games/' + id + '?page=globallibraries');
  };

  const onCopyLibrary = async (id: number) => {
    try {
      appStore.showLoading();

      await CopyGameAsync(id, true);

      toastStore.show(
        'My library',
        <div>Game copied to My Library.</div>,
        'success'
      );
    } catch (err) {
      toastStore.show(
        'My library',
        <div>Failed to copy to My Library.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      fetchLibrary();
    }
  };

  const AddCustomAction = (props: GridCellProps) => {
    return (
      <>
        <ActionsCell {...props}>
          <ActionCell
            title={'View library'}
            icon={'visibility'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onViewLibrary(props.dataItem.id)}
          />
          <ActionCell
            title={'Copy to my library'}
            icon={'content_copy'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onCopyLibrary(props.dataItem.id)}
          />
        </ActionsCell>
      </>
    );
  };

  const StatusColoring = (props: GridCellProps) => {
    return (
      <td>
        <span className={'text-success'}>{props.dataItem.status}</span>
      </td>
    );
  };

  const handleTitleClick = (id: number) => {
    navigate('/global-games/' + id + '?page=globallibraries');
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}</td>;
  };

  const CellEllipsisAndTooltip = (props: GridCellProps) => {
    return (
      <td title={props.dataItem.languages}>
        <div className={'grid-ellipsis-cell'}>{props.dataItem.languages}</div>
      </td>
    );
  };

  return (
    <>
      <Toolbar title={'Global Games'}>
        <HelpSupport
          title="Global Library - Games"
          url="https://forum.catalystglobal.com/t/global-library-games/"
        />
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search global games'}
          columnsToSearch={['name', 'languages', 'createdBy']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            const newState = {
              ...e.dataState,
              filter: {
                logic: 'and',
                filters: [e.dataState.filter!]
              }
            } as State;
            setDataState(newState);
          }}></GridToolBar>
        <ExcelExport
          data={libraries.data}
          ref={gridExportRef}
          fileName={`libraries.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-library'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={libraries}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'name'}
                title={'Name'}
                columnMenu={ColumnMenu}
                cell={onColumnTextClick}
              />
              <Column
                field={'createdDateUtc'}
                title={'Date Created'}
                cell={DateCell}
              />
              <Column
                field={'createdBy'}
                title={'Game Designer'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'languages'}
                title={'Languages'}
                cell={CellEllipsisAndTooltip}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'version'}
                title={'Version'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'organisation'}
                title={'Organisation Name'}
                columnMenu={ColumnMenu}
              />
              <Column title={'Actions'} cell={onAddCustomAction} width={120} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'card' && (
            <GlobalLibraryCardView
              data={libraries.data}
              onTitleClick={handleTitleClick}
              onView={onViewLibrary}
              onCopy={onCopyLibrary}
              total={libraries.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>
      </div>
    </>
  );
};
export default GlobalLibraryGrid;
