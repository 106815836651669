import _ from 'lodash';
import { GameDocument } from '../../../types/game-document';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';
import {
  CopyResourceAsync,
  DeleteResourceAsync,
  GetResourceKeys,
  ImportResourceAsync
} from '../resources';
import { ExportedItemData } from '../../../types/game-document/entities/exported-item';
import { FilterResourcePacks } from '../resource-packs';

/**
 * Adds a new Item to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Item
 * @param description - The Description for the new Item
 * @returns The updated Game Document
 */
export const AddItemAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string,
  summaryResId: string,
  imageResId: string
) => {
  let items = gameDocument.assets.items ?? [];
  let itemName = await GetNextAssetNameAsync(items, name);
  items.push({
    id: uuid(),
    name: itemName,
    description,
    titleResId,
    summaryResId,
    imageResId
  });
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Deletes the identified Item from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param itemId - The ID of the Item to delete
 * @returns The updated Game Document
 */
export const DeleteItemAsync = async (
  gameDocument: GameDocument,
  itemId: string
) => {
  let items = gameDocument.assets.items ?? [];
  let itemIndex = items.findIndex((i) => i.id === itemId)!;
  if (itemIndex !== -1) {
    for (const res of GetResourceKeys(items[itemIndex])) {
      await DeleteResourceAsync(gameDocument, items[itemIndex][res]!);
    }
    items.splice(itemIndex, 1);
  }
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Updates the identified Item in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param itemId - The ID of the Item to update
 * @param item - The updated Item
 * @param renameDuplicate - Auto rename 'name' key if duplicate
 * @constructor
 */
export const UpdateItemAsync = async (
  gameDocument: GameDocument,
  itemId: string,
  item: ItemEntity,
  renameDuplicate: boolean = true
) => {
  let items = gameDocument.assets.items ?? [];
  let itemIndex = items.findIndex((i) => i.id === itemId)!;
  if (renameDuplicate)
    item.name = await GetNextAssetNameAsync(items, item.name, item.id);
  items[itemIndex] = item;
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Create a copy of the Item in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param itemId - The ID of the Item to copy
 * @param copiedItemId - The new ID of the copied Item
 * @returns The updated Game Document
 */
export const CopyItemAsync = async (
  gameDocument: GameDocument,
  itemId: string,
  copiedItemId: string = uuid()
) => {
  let items = gameDocument.assets.items ?? [];
  let itemIndex = items.findIndex((i) => i.id === itemId)!;
  if (itemIndex !== -1) {
    let itemCopy: ItemEntity = {
      ...items[itemIndex],
      titleResId: uuid(),
      summaryResId: uuid(),
      imageResId: uuid()
    };
    for (const res of GetResourceKeys(itemCopy)) {
      await CopyResourceAsync(
        gameDocument,
        items[itemIndex][res]!,
        itemCopy[res]
      );
    }
    itemCopy.id = copiedItemId;
    itemCopy.name += '-copy';
    itemCopy.name = await GetNextAssetNameAsync(
      items,
      itemCopy.name,
      itemCopy.id
    );
    items.push(itemCopy);
  }
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Get all items from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetItems = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.items ?? [];
};

export const ExportItems = (
  gameDocument: GameDocument,
  selectedIds: string[]
): ExportedItemData => {
  const selectedItems = gameDocument.assets?.items?.filter((item) =>
    selectedIds.includes(item.id)
  );

  const currentResources = gameDocument.resources;

  let relatedResources: ResourceEntity[] = [];

  selectedItems?.forEach((item) => {
    relatedResources = _.concat(
      relatedResources,
      GetItemResources(item, currentResources)
    );
  });

  const resourcePacks = FilterResourcePacks(gameDocument, relatedResources);

  return {
    items: selectedItems!,
    resources: relatedResources,
    resourcePacks: resourcePacks
  };
};

export const ImportItemsAsync = async (
  gameDocument: GameDocument,
  exportedItemData: ExportedItemData
) => {
  const items = gameDocument.assets.items;

  const exportedResources = exportedItemData.resources;
  const exportedResourcePacks = exportedItemData.resourcePacks;
  debugger;
  for (const item of exportedItemData.items) {
    const newTitle = _.cloneDeep(item);
    newTitle.id = uuid();

    newTitle.titleResId = await ImportResourceAsync(
      gameDocument,
      item.titleResId,
      exportedResources,
      exportedResourcePacks
    );

    newTitle.summaryResId = await ImportResourceAsync(
      gameDocument,
      item.summaryResId,
      exportedResources,
      exportedResourcePacks
    );

    newTitle.imageResId = await ImportResourceAsync(
      gameDocument,
      item.imageResId,
      exportedResources,
      exportedResourcePacks
    );

    items?.push(newTitle);
  }

  return MergeAssets(gameDocument, items!, 'items');
};

export const GetItemResources = (
  title: ItemEntity,
  resources: ResourceEntity[]
): ResourceEntity[] => {
  const resIds: string[] = [];
  if (title.titleResId) resIds.push(title.titleResId);
  if (title.summaryResId) resIds.push(title.summaryResId);
  if (title.imageResId) resIds.push(title.imageResId);

  return _.filter(resources, (resource) => _.includes(resIds, resource.id));
};
