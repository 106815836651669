import * as React from 'react';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import debounce from 'lodash/debounce';

type GridMode = 'grid' | 'card' | 'cal';

export interface GridToolbarDataStateChangeEvent {
  dataState: State;
}

export interface GridToolbarModeChangeEvent {
  gridMode: GridMode;
}

export interface GridToolBarProps extends State {
  /**
   * The placeholder to display in the search input.
   */
  searchPlaceholder?: string;
  /**
   * The ref for the ExcelExport that should be linked to the export button.
   */
  exportRef?: React.MutableRefObject<ExcelExport | null>;
  /**
   * Determines if the clear button is visible on the toolbar.
   * @default true
   */
  showClear?: boolean;
  /**
   * Determines if the grid mode toggle button is visible on the toolbar.
   */
  showGridMode?: boolean;
  /**
   * Determines if the card mode toggle button is visible on the toolbar.
   */
  showCardMode?: boolean;
  /**
   * Determines if the calendar mode toggle button is visible on the toolbar.
   */
  showCalendarMode?: boolean;
  /**
   * Determines if the export button is visible on the toolbar.
   * @remarks
   * exportRef must also be set in order for the button to be visible.
   */
  showExport?: boolean;
  /**
   * Determines if the export as PDF button is visible on the toolbar.
   * @remarks
   * exportRef must also be set in order for the button to be visible.
   */
  showPDFExport?: boolean;
  /**
   * Triggered after gridMode change.
   */
  onGridModeChange?: (event: GridToolbarModeChangeEvent) => void;
  /**
   * Fires when the data state of the toolbar is changed.
   */
  onDataStateChange?: (event: GridToolbarDataStateChangeEvent) => void;
  /**
   * Specifies which grid columns should be included in the search filter.
   */
  columnsToSearch?: string[];
  /**
   * @hidden
   */
  children?: React.ReactNode;

  /**
   * handle for download file report
   */
  handleExportToPdf?: () => Promise<void>;

  /**
   * handle for custom download excel file report
   */
  handleCustomExportToExcel?: () => Promise<void>;

  useCustomExportExcel?: boolean;

  isDisablePdfButton?: boolean;
  defaultGridMode?: GridMode;
  showJsonExport?: boolean;
  handleJsonExport?: () => Promise<void>;
  showJsonImport?: boolean;
  handleJsonImport?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
}

export const GridToolBar = ({
  showClear = true,
  showGridMode = true,
  showCardMode = true,
  showCalendarMode = false,
  showExport = true,
  showPDFExport = false,
  handleExportToPdf,
  handleCustomExportToExcel,
  useCustomExportExcel = false,
  isDisablePdfButton = false,
  defaultGridMode = 'grid',
  showJsonExport = false,
  handleJsonExport,
  showJsonImport = false,
  handleJsonImport,
  ...props
}: GridToolBarProps) => {
  const [searchText, setSearchText] = React.useState<string>('');
  const [gridMode, setGridMode] = React.useState<GridMode>(defaultGridMode);

  /**
   * Only show the mode toggle if grid mode is enabled and at least 1 other mode.
   */
  const canChangeMode = showGridMode && (showCardMode || showCalendarMode);

  /**
   * Uses the searchTerm to construct a composite grid filter, or clears it.
   * @param searchTerm
   * @remarks
   * This function is debounced so if a user types rapidly into the search
   * it won't "smash" the api endpoints.
   * useRef so we don't recreate the function on every re-render.
   */
  const emitFilterChange = React.useRef(
    debounce(async (searchTerm) => {
      if ((searchTerm ?? '') === '') {
        // clear the filter
        raiseDataStateChangeEvent({ filter: { logic: 'or', filters: [] } });
      } else {
        // build a filter from the columnsToSearch
        raiseDataStateChangeEvent({
          filter: { logic: 'or', filters: getFilters(searchTerm) }
        });
      }
    }, 300)
  ).current;

  /**
   * When the component unmounts, the search request could be still in progress.
   * In this case, an error will occur when the searchText state is set.
   * Cancel the debounced function to resolve this problem
   */
  React.useEffect(() => {
    return () => {
      emitFilterChange.cancel();
    };
  }, [emitFilterChange]);

  /**
   * Stores the search term in state then emits the debounced filter change event.
   * @param event - the search input change event
   */
  const handleFilterChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    emitFilterChange(searchTerm);
  };

  /**
   * Clear the Search Term and Filters.
   */
  const handleFilterClear = () => handleFilterChange('');

  /**
   * Changes the current grid mode and raises the grid mode change event.
   * @param mode - the Grid Mode
   */
  const handleGridModeChange = (mode: GridMode) => {
    setGridMode(mode);
    raiseGridModeChangeEvent(mode);
  };

  /**
   * Raise the Grid Mode change event.
   * @param mode - the Grid Mode
   */
  const raiseGridModeChangeEvent = (mode: GridMode) => {
    props.onGridModeChange?.({ gridMode: mode });
  };

  /**
   * Export the current grid data to xlsx.
   * @remarks
   * TODO: This currently ignores filters and pagination.
   * It is possible to do this, however all previous examples returned blank rows.
   */
  const handleExport = () => {
    props.exportRef?.current?.save();
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  /**
   * Constructs a composite 'contains' filter using the ColumnsToSearch.
   * @param searchTerm
   */
  const getFilters = (searchTerm: string) => {
    const filters: (CompositeFilterDescriptor | FilterDescriptor)[] = [];
    props.columnsToSearch?.forEach((col) =>
      filters.push({
        field: col,
        operator: 'contains',
        value: `${searchTerm}`
      })
    );
    return filters;
  };

  /**
   * Reassembles the grid data state based on the search term / filters.
   */
  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take: props.take !== undefined ? props.take : 20,
      group: props.group
    };
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <div className={'grid-toolbar'}>
      <GridToolbar>
        <div className={'d-flex w-100 align-items-center'}>
          <div className={'flex-grow-1 input-group'}>
            <Input
              className={'form-control'}
              value={searchText}
              placeholder={props.searchPlaceholder}
              onChange={(e) => handleFilterChange(e.value)}
            />
            <div className={'input-group-append'}>
              <Button
                onClick={() => handleFilterChange(searchText)}
                title={props.searchPlaceholder ?? 'Search'}>
                <span className={'material-symbols-outlined'}>search</span>
              </Button>
            </div>
          </div>

          <div className={'d-flex align-items-center'}>
            {showClear && (
              <Button
                className={'ms-2'}
                title={'Clear filters'}
                onClick={handleFilterClear}>
                <span className={'material-symbols-outlined'}>
                  filter_alt_off
                </span>
              </Button>
            )}

            {canChangeMode && (
              <ButtonGroup className={'ms-2'}>
                {showGridMode && (
                  <Button
                    title={'View grid'}
                    themeColor={gridMode === 'grid' ? 'secondary' : 'base'}
                    onClick={() => handleGridModeChange('grid')}>
                    <span className={'material-symbols-outlined'}>
                      view_list
                    </span>
                  </Button>
                )}
                {showCardMode && (
                  <Button
                    title={'View cards'}
                    themeColor={gridMode === 'card' ? 'secondary' : 'base'}
                    onClick={() => handleGridModeChange('card')}>
                    <span className={'material-symbols-outlined'}>
                      grid_view
                    </span>
                  </Button>
                )}
                {showCalendarMode && (
                  <Button
                    title={'View calendar'}
                    themeColor={gridMode === 'cal' ? 'secondary' : 'base'}
                    onClick={() => handleGridModeChange('cal')}>
                    <span className={'material-symbols-outlined'}>
                      calendar_month
                    </span>
                  </Button>
                )}
              </ButtonGroup>
            )}

            {showExport && props.exportRef && !useCustomExportExcel && (
              <Button
                className={'ms-2'}
                onClick={handleExport}
                title={'Export to excel'}>
                <span className={'material-symbols-outlined'}>
                  file_download
                </span>
              </Button>
            )}

            {showJsonExport && (
              <Button
                className={'ms-2'}
                onClick={handleJsonExport}
                title={'Export to json'}>
                <span className="material-symbols-outlined">
                  download_for_offline
                </span>
              </Button>
            )}

            {showJsonImport && (
              <>
                <Button
                  onClick={handleUploadButtonClick}
                  className={'ms-2'}
                  title={'Import from json'}>
                  <span className="material-symbols-outlined">upload</span>
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".json"
                  style={{ display: 'none' }} // Hide the input element
                  onChange={handleJsonImport}
                />
              </>
            )}

            {showExport &&
              props.exportRef &&
              useCustomExportExcel &&
              handleCustomExportToExcel !== undefined && (
                <Button
                  className={'ms-2'}
                  onClick={handleCustomExportToExcel}
                  title={'Export to excel'}>
                  <span className={'material-symbols-outlined'}>
                    file_download
                  </span>
                </Button>
              )}

            {showPDFExport && handleExportToPdf !== undefined && (
              <Button
                disabled={isDisablePdfButton}
                className={'ms-2'}
                onClick={handleExportToPdf}
                title={'Export to PDF'}>
                <span className={'material-symbols-outlined'}>
                  picture_as_pdf
                </span>
              </Button>
            )}

            {props.children && <div className={'ms-2'}>{props.children}</div>}
          </div>
        </div>
      </GridToolbar>
    </div>
  );
};
