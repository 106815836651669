import { ComponentType, useContext, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { ResourceEntity, TitleEntity } from '../../../types/game-document/';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { TitleEditor } from './title-editor';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';

interface TitleEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<TitleEntity>;
  editorResource: EntityEditor<ResourceEntity>[];
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<TitleEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => void;
  editorMode: EditorMode;
}

export const TitleEditorWindow: ComponentType<TitleEditorWindowProps> = ({
  editorEntity,
  toggleDialog,
  editorResource,
  onSubmit,
  editorMode,
  ...props
}: TitleEditorWindowProps) => {
  const [state] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<TitleEntity>({ ...editorEntity.entity });
  const [resources, setResources] = useState<ResourceEntity[]>([
    ...editorResource.map((title) => title.entity)
  ]);

  const [requiredInputs, setRequiredInputs] = useState<
    RequiredFields<TitleEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;

    if (name === 'titleResId' || name === 'summaryResId') {
      const key = entity[name]!;
      setResources(
        resources.map((title) =>
          title.id === key
            ? { ...title, value: value! as string }
            : { ...title }
        )
      );
    } else {
      setEntity({ ...entity, [name!]: value });
    }
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    setResources(
      resources.map((title) =>
        title.id === entity.imageResId
          ? {
              ...title,
              value: url,
              size: size,
              name: title.id,
              description: name ?? ''
            }
          : title
      )
    );
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredInputs.map((input) => {
      const entityValue = entity[input.name as keyof TitleEntity];

      if (input.name === 'name') {
        const titleIndex =
          state.gameDocument?.assets?.titles?.findIndex(
            (title) => title.name.toLowerCase() === entityValue?.toLowerCase()
          ) ?? -1;
        if (entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else if (
          titleIndex !== -1 &&
          state.gameDocument?.assets?.titles![titleIndex]?.id !== entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        } else {
          input.errorMessage = '';
        }
      } else if (input.name === 'titleResId') {
        const resourceIndex = resources.findIndex(
          (resource) => resource.id === entityValue
        );
        if (resources[resourceIndex]?.value === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredInputs(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={600}
      initialHeight={450}
      title={editorEntity.isNew ? 'New title' : editorEntity.entity.name}
      {...props}>
      <FluidForm>
        <TitleEditor
          editorMode={editorMode}
          entity={entity}
          resources={resources}
          handleInputChange={handleInputChange}
          handleImageSelected={handleImageSelected}
          requiredFields={requiredInputs}
        />
      </FluidForm>

      <WindowActionsBar>
        <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => {
            if (isInputValid()) {
              const entityResources = resources.map((item, index) => ({
                isNew: editorResource[index].isNew,
                entity: item
              })) as EntityEditor<ResourceEntity>[];
              onSubmit({ isNew: editorEntity.isNew, entity }, entityResources);
            }
          }}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
