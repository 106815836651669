import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { User } from '../types/user';

import { Country } from '../types/country';
import {
  EventPlaylist,
  EventPlaylistFacilitator,
  EventPlaylistLocation
} from '../types/event';
import { Library } from '../types/library';
import { EnvironmentList } from '../types/environment';
import { ColView } from './col-view';
import { formatDateToDDMMYYYY } from '../utils/date';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { QRLink } from './QRLink';
import { QRCode } from '@progress/kendo-react-barcodes';

export interface ActivityEventProps {
  event?: EventPlaylist;
  index?: number;
  users?: User[];
  libraries?: Library[];
  environments?: EnvironmentList[];
  languages?: string[];
  countries?: Country[];
  facilitators?: EventPlaylistFacilitator[];
  locations?: EventPlaylistLocation[];
  onChange?: (game: EventPlaylist, index: number) => void;
  onDeleteGame?: (index: number) => void;
  onCopyGame?: (index: number) => void;
}

export type dictionary = {
  id: number;
  name: string;
};

export const ActivityEventView = ({
  event,
  index,
  facilitators,
  locations
}: ActivityEventProps) => {
  const [eventActivity, setEventActivity] = useState<EventPlaylist>({});
  const [gamelocations, setGameLocations] = useState<string>('');
  const [, setFacilitators] = useState<string>('');
  const anchor = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState(false);

  const getLocations = () => {
    const data = locations;
    if (data) {
      const formattedLocations = data
        .map((l) => `${l.country} ${l.city}`)
        .join(', ');
      setGameLocations(formattedLocations);
    }
  };

  const getFacilitators = () => {
    const data = facilitators;
    if (data) {
      const facilitators = data
        .map((l) => {
          return l.fullName;
        })
        .join(', ');
      setFacilitators(facilitators);
    }
  };

  useEffect(() => {
    if (event) {
      setEventActivity(event);
    }
  }, [event]);

  useEffect(() => {
    if (locations) {
      getLocations();
    }
  }, [locations]);

  useEffect(() => {
    if (facilitators) {
      getFacilitators();
    }
  }, [facilitators]);

  return (
    <>
      <div className={'mt-4'}>Activity {(index as number) + 1}</div>
      <div className={'card p-4'}>
        <Row className={'p-2'}>
          <Col md={10}>
            <div className={'d-flex flex-wrap'}>
              <ColView
                className={'d-flex flex-column'}
                colSpec={6}
                labelClassName={'text-muted'}
                label={'Date'}
                value={`${formatDateToDDMMYYYY(
                  eventActivity?.startDateUtc!,
                  true
                )} - ${formatDateToDDMMYYYY(eventActivity?.endDateUtc!, true)}`}
              />

              <ColView
                colSpec={6}
                className={'d-flex flex-column'}
                labelClassName={'text-muted'}
                label={'Code'}
                value={`${eventActivity?.code}`}
              />

              <ColView
                className={'d-flex flex-column mt-2'}
                colSpec={6}
                labelClassName={'text-muted'}
                label={'Location'}
                value={gamelocations}></ColView>
              <ColView
                className={'d-flex flex-column mt-2'}
                colSpec={6}
                labelClassName={'text-muted'}
                label={'Language'}
                value={eventActivity?.language}
              />
            </div>
          </Col>
          <Col md={2}>
            {eventActivity.gameId &&
              eventActivity?.environmentHostUrl &&
              eventActivity.type === 'Game' && (
                <QRCode
                  size={'120'}
                  value={`${eventActivity?.environmentHostUrl}/games/${eventActivity?.code}`}
                  errorCorrection="M"
                />
              )}

            {eventActivity.gameId &&
              eventActivity?.environmentHostUrl &&
              eventActivity.type === 'ARVR' && (
                <QRCode
                  size={'120'}
                  value={`arpyramid://catalystglobal?${eventActivity?.environmentHostUrl}?${eventActivity?.code}`}
                  errorCorrection="M"
                />
              )}
          </Col>
        </Row>

        {eventActivity.gameId && (
          <>
            <Row className={'p-2'}>
              <Col md={10} className="d-flex">
                <ColView
                  className={'d-flex flex-column fw-bold'}
                  colSpec={6}
                  labelClassName={'text-muted'}
                  label={'Game'}
                  value={eventActivity?.game}
                />
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={6}
                  labelClassName={'text-muted'}
                  label={'Pax'}
                  value={eventActivity?.approxPax}
                />
              </Col>
            </Row>

            <Row className={'p-2'}>
              <Col md={'12'} className={'d-flex flex-column'}>
                <small className={'text-muted'}>Game - Facilitator Link</small>
                {eventActivity?.environmentHostUrl &&
                  eventActivity?.environmentHostUrl !== '' && (
                    <QRLink
                      title={'Game - Facilitator Link'}
                      url={`${eventActivity?.environmentHostUrl}/games/${eventActivity?.code}/facilitator`}
                    />
                  )}
              </Col>
            </Row>

            {eventActivity.type === 'Game' && (
              <>
                <Row className={'p-2'}>
                  <Col md={'12'} className={'d-flex flex-column'}>
                    <small className={'text-muted'}>
                      Game - Participant URL
                    </small>
                    {eventActivity?.environmentHostUrl &&
                      eventActivity?.environmentHostUrl !== '' && (
                        <QRLink
                          title={'Game - Participant URL'}
                          url={`${eventActivity?.environmentHostUrl}/games/${eventActivity?.code}`}
                        />
                      )}
                  </Col>
                </Row>

                <Row className={'p-2'}>
                  <Col md={'12'} className={'d-flex flex-column'}>
                    <small className={'text-muted'}>Game - Pre-load URL</small>
                    {eventActivity?.environmentHostUrl &&
                      eventActivity?.environmentHostUrl !== '' && (
                        <QRLink
                          title={'Game - Pre-load URL'}
                          url={`${eventActivity?.environmentHostUrl}/preload/${eventActivity?.code}`}
                        />
                      )}
                  </Col>
                </Row>
              </>
            )}

            {(eventActivity.type === 'AR' || eventActivity.type === 'VR') && (
              <>
                <Row className={'p-2'}>
                  <Col md={'12'} className={'d-flex flex-column'}>
                    <small className={'text-muted'}>
                      AR/VR - Participant URL
                    </small>
                    {eventActivity?.environmentHostUrl &&
                      eventActivity?.environmentHostUrl !== '' && (
                        <QRLink
                          title={'AR/VR - Participant URL'}
                          url={`arpyramid://catalystglobal?${eventActivity?.environmentHostUrl}?${eventActivity?.code}`}
                        />
                      )}
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {eventActivity.assessmentId && (
          <>
            <Row className={'p-2'}>
              <Col md={10} className="d-flex">
                <ColView
                  className={'d-flex flex-column fw-bold'}
                  colSpec={6}
                  labelClassName={'text-muted'}
                  label={'Assessment'}
                  value={eventActivity?.assessmentName}
                />
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={6}
                  labelClassName={'text-muted'}
                  label={'Pax'}
                  value={eventActivity?.assessmentApproxPax}
                />
              </Col>
            </Row>

            <Row className={'p-2'}>
              <Col md={'12'} className={'d-flex flex-column'}>
                <small className={'text-muted'}>
                  Assessment - Facilitator Link
                </small>
                {eventActivity?.environmentHostUrl &&
                  eventActivity?.environmentHostUrl !== '' && (
                    <QRLink
                      title={'Assessment - Facilitator Link'}
                      url={`${eventActivity?.environmentHostUrl}/facilitator/assessment/${eventActivity?.code}`}
                    />
                  )}
              </Col>
            </Row>

            <Row className={'p-2'}>
              <Col md={'12'} className={'d-flex flex-column'}>
                <small className={'text-muted'}>
                  Assessment - Participant URL
                </small>
                {eventActivity?.environmentHostUrl &&
                  eventActivity?.environmentHostUrl !== '' && (
                    <QRLink
                      title={'Assessment - Participant URL'}
                      url={`${eventActivity?.environmentHostUrl}/assessments/${eventActivity?.code}`}
                    />
                  )}
              </Col>
            </Row>
          </>
        )}

        <Row className={'p-2'}>
          <ColView
            className={'d-flex flex-column'}
            colSpec={4}
            labelClassName={'text-muted'}
            label={'Notes'}
            value={eventActivity?.notes}
          />
        </Row>
        <Tooltip
          anchorElement="target"
          open={show}
          targetElement={anchor.current}
          openDelay={1}
          appendTo={anchor.current}
          position={'right'}
        />
      </div>
    </>
  );
};
