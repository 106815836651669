import { GameDocumentState } from '../../contexts/game-document';
import {
  RadioButton,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { UpdatePreGameSettingAsync } from '../../utils/game-document/settings';
import { PreGame } from '../../constants/settings';
import { GameDocument } from '../../types/game-document';

interface PreGameSettingsProps {
  state: GameDocumentState;
  onChange: (gameDocument: GameDocument) => void;
}

const PreGameSettings = (props: PreGameSettingsProps) => {
  const { state, onChange } = props;
  const [isEnableGameLobby, setIsEnableGameLobby] = useState<boolean>(
    state.gameDocument?.settings.preGame.showPreGameLobby!
  );

  const handlePreGameSettingChange = (e: RadioButtonChangeEvent) => {
    let preGameSetting = state.gameDocument?.settings.preGame!;

    UpdatePreGameSettingAsync(state.gameDocument!, {
      ...preGameSetting,
      [e.target.element?.name!]: e.value
    }).then((updatedGameDocument) => {
      onChange(updatedGameDocument);
    });
    if (e.target.element?.name === PreGame.lobby) {
      setIsEnableGameLobby(e.value);
    }
  };

  useEffect(() => {
    setIsEnableGameLobby(
      state.gameDocument?.settings.preGame.showPreGameLobby!
    );
  }, [state.gameDocument?.settings.preGame.showPreGameLobby]);

  //the logic for this setting is using IsShow, so the 'Show' Radio button will send "true" value, and for the inital value set on false (Hide)
  return (
    <>
      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Game Lobby</span>
        <RadioButton
          name={PreGame.lobby}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameLobby === true
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.lobby}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameLobby === false
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of pre-game menu tabs.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Teams</span>
        <RadioButton
          name={PreGame.teams}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameTeams === true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.teams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.showPreGameTeams ===
                false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of teams in lobby.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Create teams</span>
        <RadioButton
          name={PreGame.createTeams}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameCreateTeams ===
              true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.createTeams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.showPreGameCreateTeams ===
                false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of team creation function.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Join / leave teams</span>
        <RadioButton
          name={PreGame.joinLeaveTeams}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameJoinLeaveTeams ===
              true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.joinLeaveTeams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.showPreGameJoinLeaveTeams ===
                false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of join/leave team function.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Inventory</span>
        <RadioButton
          name={PreGame.inventory}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameInventory ===
              true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.inventory}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.showPreGameInventory ===
                false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of inventory items in lobby.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Roles</span>
        <RadioButton
          name={PreGame.roles}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameRoles === true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.roles}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.showPreGameRoles ===
                false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of roles in lobby.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Chat</span>
        <RadioButton
          name={PreGame.chats}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameChat === true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.chats}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.showPreGameChat === false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of chat function in lobby.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Team Required</span>
        <RadioButton
          name={PreGame.teamRequired}
          value={true}
          label={'True'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.isPreGameTeamRequired ===
              true &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.teamRequired}
          value={false}
          label={'False'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              state.gameDocument?.settings.preGame.isPreGameTeamRequired ===
                false) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Players must join team to enter a game.
        </span>
      </div>
    </>
  );
};

export default PreGameSettings;
