import React from 'react';
import { StepEditor } from './step-editor';
import { IdentifierSelector } from './identifier-selector';
import { ConditionSelector } from './condition-selector';
import OperationSelector from './operation-selector';
import { AddStep } from './add-step';
import { uuid } from '../../types/common-helper';
import {
  AlgorithmControlStructure,
  AlgorithmStep,
  CompoundStatement
} from '../../types/algorithm';
import ConditionalOperations from '../../constants/conditional-operations';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import SelectionItem from '../../types/selection-item';

type StructureType = 'IF' | 'ELSE';

interface ControlStructureState {
  ifSteps: any[];
  elseSteps: any[];
  ifCompoundStatements: any[];
  elseCompoundStatements: any[];
  onChangeStructure?: (steps: AlgorithmControlStructure) => void;
  onStructureDelete?: (step: AlgorithmControlStructure) => void;
  onMoveUp?: (step: AlgorithmStep) => void;
  onMoveDown?: (step: AlgorithmStep) => void;
  onCopy?: (step: AlgorithmStep) => void;
  selectedIdentifier?: string;
  condtionalOperationList: SelectionItem[];
}

export class ControlStructureEditor extends React.Component<
  AlgorithmControlStructure,
  ControlStructureState
> {
  constructor(props: AlgorithmControlStructure) {
    super(props);
    this.state = {
      ifSteps: props.ifSteps ?? [],
      elseSteps: props.elseSteps ?? [],
      ifCompoundStatements: props.ifCompoundStatements ?? [],
      elseCompoundStatements: props.elseCompoundStatements ?? [],
      onChangeStructure: this.handleDataChange.bind(this),
      onStructureDelete: this.handleDelete.bind(this),
      onMoveUp: this.handleMoveUp.bind(this),
      onMoveDown: this.handleMoveDown.bind(this),
      selectedIdentifier: this.props.identifier,
      condtionalOperationList: ConditionalOperations
    };
  }

  handleDataChange(steps: AlgorithmControlStructure) {
    if (this.props.onChangeStructure) {
      this.props.onChangeStructure(steps);
    }
  }

  handleDelete(steps: AlgorithmControlStructure) {
    if (this.props.onDelete) {
      this.props.onDelete(steps);
    }
  }

  handleMoveUp(step: AlgorithmStep) {
    if (this.props.onMoveUp) {
      this.props.onMoveUp(step);
    }
  }

  handleMoveDown(step: AlgorithmStep) {
    if (this.props.onMoveDown) {
      this.props.onMoveDown(step);
    }
  }

  handleCopy(step: AlgorithmStep) {
    if (this.props.onCopy) {
      this.props.onCopy(step);
    }
  }

  generateOperationList() {
    let filteredOperations = ConditionalOperations.filter((item) =>
      this.props.condition
        ? item.availableOnConditions?.includes(this.props.condition)
        : !item.availableOnConditions
    );

    if (this.props.condition && !filteredOperations.length) {
      filteredOperations = ConditionalOperations.filter(
        (item) => !item.availableOnConditions
      );
    }

    return filteredOperations;
  }

  componentDidMount(): void {
    const filteredOperations = this.generateOperationList();

    this.setState({
      ...this.state,
      ifSteps: this.props.ifSteps ?? [],
      elseSteps: this.props.elseSteps ?? [],
      ifCompoundStatements: this.props.ifCompoundStatements ?? [],
      elseCompoundStatements: this.props.elseCompoundStatements ?? [],
      condtionalOperationList: filteredOperations
    });
  }

  componentDidUpdate(prevProps: Readonly<AlgorithmControlStructure>): void {
    if (!isEqual(prevProps, this.props)) {
      const filteredOperations = this.generateOperationList();

      this.setState({
        ...this.state,
        ifSteps: this.props.ifSteps ?? [],
        elseSteps: this.props.elseSteps ?? [],
        ifCompoundStatements: this.props.ifCompoundStatements ?? [],
        elseCompoundStatements: this.props.elseCompoundStatements ?? [],
        condtionalOperationList: filteredOperations
      });
    }
  }

  render() {
    const gameStep = this.props;
    const {
      identifier,
      condition,
      operation,
      argumentAssetId,
      argumentQuantity,
      ifCompoundStatements
    } = this.props;

    const addIfStep = (index: number) => {
      let newSteps = this.props as AlgorithmControlStructure;

      if (newSteps.ifSteps === undefined) {
        newSteps.ifSteps = [{ id: uuid() }];
      } else {
        newSteps.ifSteps.splice(index, 0, { id: uuid() });
      }
      this.setState({ ifSteps: newSteps.ifSteps });
      this.handleDataChange(newSteps);
    };

    const addIfConjunctionStep = (index: number) => {
      let newSteps = { ...this.props } as AlgorithmControlStructure;

      if (newSteps.ifCompoundStatements === undefined) {
        newSteps.ifCompoundStatements = [];
      }

      newSteps.ifCompoundStatements.push({ id: uuid(), type: 'and' });

      this.setState({ ifCompoundStatements: newSteps.ifCompoundStatements });

      this.handleDataChange(newSteps);
    };

    const addIfDisjunctionStep = (index: number) => {
      let newSteps = { ...this.props } as AlgorithmControlStructure;

      if (newSteps.ifCompoundStatements === undefined) {
        newSteps.ifCompoundStatements = [];
      }

      newSteps.ifCompoundStatements.push({ id: uuid(), type: 'or' });

      this.setState({ ifCompoundStatements: newSteps.ifCompoundStatements });

      this.handleDataChange(newSteps);
    };

    const handleIfStepChange = (step: AlgorithmStep) => {
      var newSteps = this.props as AlgorithmControlStructure;

      if (newSteps && newSteps.ifSteps) {
        for (let index = 0; index < newSteps.ifSteps.length; index++) {
          const stp = newSteps.ifSteps[index];

          if (stp.id === step.id) {
            newSteps.ifSteps[index] = step;
            break;
          }
        }

        if (this.handleDataChange) {
          this.handleDataChange(newSteps);
        }
      }
    };

    const handleElseStepChange = (step: AlgorithmStep) => {
      var newSteps = this.props as AlgorithmControlStructure;

      if (newSteps && newSteps.elseSteps) {
        for (let index = 0; index < newSteps.elseSteps.length; index++) {
          const stp = newSteps.elseSteps[index];

          if (stp.id === step.id) {
            newSteps.elseSteps[index] = step;
            break;
          }
        }

        if (this.handleDataChange) {
          this.handleDataChange(newSteps);
        }
      }
    };

    const addElseStep = (index: number) => {
      let newSteps = this.props as AlgorithmControlStructure;

      if (newSteps.elseSteps === undefined) {
        newSteps.elseSteps = [{ id: uuid() }];
      } else {
        newSteps.elseSteps.splice(index, 0, { id: uuid() });
      }
      this.setState({ elseSteps: newSteps.elseSteps });
      this.handleDataChange(newSteps);
    };

    const handleDeleteIfStep = (step: AlgorithmStep) => {
      let newSteps: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      if (newSteps && newSteps.ifSteps) {
        newSteps.ifSteps.forEach((stp, index) => {
          if (stp.id === step.id) {
            newSteps.ifSteps?.splice(index, 1); // 2nd parameter means remove one item only
          }
        });
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    const handleCopyIfStep = (step: AlgorithmStep) => {
      let newStep = cloneDeep(step);

      let newSteps: AlgorithmControlStructure = cloneDeep({
        ...gameStep
      } as AlgorithmControlStructure);

      if (newSteps && newSteps.ifSteps) {
        newStep.id = uuid();
        newSteps.ifSteps?.push(newStep);
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    const handleCopyElseStep = (step: AlgorithmStep) => {
      let newStep = cloneDeep(step);

      let newSteps: AlgorithmControlStructure = cloneDeep({
        ...gameStep
      } as AlgorithmControlStructure);

      if (newSteps && newSteps.elseSteps) {
        newStep.id = uuid();
        newSteps.elseSteps?.push(newStep);
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    const handleDeleteElseStep = (step: AlgorithmStep) => {
      let newSteps: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      if (newSteps && newSteps.elseSteps) {
        newSteps.elseSteps.forEach((stp, index) => {
          if (stp.id === step.id) {
            newSteps.elseSteps?.splice(index, 1); // 2nd parameter means remove one item only
          }
        });
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    let ifStepIndex = 0;
    const ifSteps: any[] = [
      <AddStep
        key={uuid()}
        onAddStepClick={() => addIfStep(ifStepIndex)}
        isCompoundStatement={true}
        onAddConjunctionClick={() => addIfConjunctionStep(ifStepIndex)}
        onAddDisjunctionClick={() => addIfDisjunctionStep(ifStepIndex)}
      />
    ];

    const handleChildIFControlMoveUp = (step: AlgorithmStep) => {
      handleStepMoveUp(step, 'IF');
    };

    const handleChildIFControlMoveDown = (step: AlgorithmStep) => {
      handleStepMoveDown(step, 'IF');
    };

    const handleChildELSEControlMoveUp = (step: AlgorithmStep) => {
      handleStepMoveUp(step, 'ELSE');
    };
    const handleChildELSEControlMoveDown = (step: AlgorithmStep) => {
      handleStepMoveDown(step, 'ELSE');
    };

    const handleStepMoveUp = (step: AlgorithmStep, type: StructureType) => {
      var newSteps = [] as Array<AlgorithmControlStructure>;

      if (type === 'IF') {
        newSteps = this.props.ifSteps as Array<AlgorithmControlStructure>;
      } else if (type === 'ELSE') {
        newSteps = this.props.elseSteps as Array<AlgorithmControlStructure>;
      }

      if (newSteps) {
        var index = -1;

        var data = newSteps.find(function (item, i) {
          if (item.id === step.id) {
            index = i;
            return i;
          }
        });

        if (index > 0) {
          stepMove(newSteps, index, index - 1, type);
        }
      }
    };

    const handleStepMoveDown = (step: AlgorithmStep, type: StructureType) => {
      var newSteps = [] as Array<AlgorithmControlStructure>;

      if (type === 'IF') {
        newSteps = this.props.ifSteps as Array<AlgorithmControlStructure>;
      } else if (type === 'ELSE') {
        newSteps = this.props.elseSteps as Array<AlgorithmControlStructure>;
      }

      if (newSteps) {
        var index = -1;

        var data = newSteps.find(function (item, i) {
          if (item.id === step.id) {
            index = i;
            return i;
          }
        });

        if (index > -1 && index < newSteps.length) {
          stepMove(newSteps, index, index + 1, type);
        }
      }
    };

    const stepMove = (
      steps: Array<AlgorithmControlStructure>,
      fromIndex: number,
      toIndex: number,
      type: StructureType
    ) => {
      let newSteps: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      var element = steps[fromIndex];
      steps.splice(fromIndex, 1);
      steps.splice(toIndex, 0, element);

      if (type === 'IF') {
        newSteps.ifSteps = steps;
      } else if (type === 'ELSE') {
        newSteps.elseSteps = steps;
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    /**
     * Add compound statement
     */

    this.props.ifCompoundStatements?.forEach(
      (stepStatement: CompoundStatement, indexStatement) => {
        ifSteps.push(
          <>
            <div className={'d-flex align-items-center'}>
              <small
                className={
                  'fw-bold mt-2 text-danger compound-statement-type d-flex algin-items-top'
                }>
                <div>{stepStatement?.type?.toUpperCase()}</div>
              </small>
              <div
                className={
                  'row mt-2 d-flex justify-content-between w-full ml-1'
                }>
                <div className={'col'}>
                  <div className={'d-flex align-items-center flex-wrap'}>
                    <IdentifierSelector
                      identifier={stepStatement?.identifier}
                      onChange={(v) => {
                        handleIdentifierCompoundChange(v, indexStatement);
                      }}
                      isOnConditional={true}
                    />
                    <ConditionSelector
                      condition={stepStatement?.condition}
                      onChange={(v) =>
                        handleConditionCompoundChange(v, indexStatement)
                      }
                      selectedIdentifier={this.state.selectedIdentifier}
                    />
                    <OperationSelector
                      operationsList={ConditionalOperations}
                      operation={stepStatement?.operation}
                      argumentAssetId={stepStatement?.argumentAssetId}
                      argumentQuantity={stepStatement?.argumentQuantity}
                      onChangeOperation={(o) =>
                        handleOperationCompoundChange(o, indexStatement)
                      }
                      onChangeArgument={(o) =>
                        handleArgumentmpoundChange(o as string, indexStatement)
                      }
                      onChangeQuantity={(o) =>
                        handleQuantityCompoundChange(o ?? 0, indexStatement)
                      }
                    />
                  </div>
                </div>
                <div className={'col-auto'}>
                  <div
                    className={
                      'material-symbols-outlined text-primary me-1 cursor-pointer'
                    }
                    style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                    title={'Move Down'}
                    onClick={() =>
                      compoundStatementMove(
                        stepStatement,
                        indexStatement,
                        indexStatement + 1
                      )
                    }>
                    move_down
                  </div>
                  <div
                    className={
                      'material-symbols-outlined text-primary me-1 cursor-pointer'
                    }
                    style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                    title={'Move Up'}
                    onClick={() =>
                      compoundStatementMove(
                        stepStatement,
                        indexStatement,
                        indexStatement - 1
                      )
                    }>
                    move_up
                  </div>
                  <div
                    className={
                      'material-symbols-outlined me-1 text-primary cursor-pointer'
                    }
                    style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                    title={'Copy'}
                    onClick={() => handleCopyCompoundStatement(stepStatement)}>
                    content_copy
                  </div>
                  <div
                    className={
                      'material-symbols-outlined me-1 text-danger cursor-pointer'
                    }
                    style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                    title={'Delete'}
                    onClick={() => handleDeleteCompoundChange(indexStatement)}>
                    delete
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    );

    ifSteps.push(
      <>
        <a className={'divider-solid mt-3 mb-3'}>
          <span>then</span>
        </a>
      </>
    );

    this.state.ifSteps?.forEach((step) => {
      ifStepIndex++;

      ifSteps.push(
        <StepEditor
          key={step.id}
          {...step}
          onChange={handleIfStepChange}
          onDelete={handleDeleteIfStep}
          onMoveUp={handleChildIFControlMoveUp}
          onMoveDown={handleChildIFControlMoveDown}
          onCopy={handleCopyIfStep}
        />
      );
      ifSteps.push(
        <AddStep
          key={uuid()}
          onAddStepClick={() => addIfStep(ifStepIndex)}
          isCompoundStatement={true}
          onAddConjunctionClick={() => addIfConjunctionStep(ifStepIndex)}
          onAddDisjunctionClick={() => addIfDisjunctionStep(ifStepIndex)}
        />
      );
    });

    let elseStepIndex = 0;
    const elseSteps: any[] = [
      <AddStep
        key={uuid()}
        onAddStepClick={() => addElseStep(elseStepIndex)}
        isCompoundStatement={false}
        onAddConjunctionClick={() => addIfConjunctionStep(ifStepIndex)}
        onAddDisjunctionClick={() => addIfDisjunctionStep(ifStepIndex)}
      />
    ];

    this.state.elseSteps?.forEach((step) => {
      elseStepIndex++;
      elseSteps.push(
        <StepEditor
          key={step.id}
          {...step}
          onChange={handleElseStepChange}
          onDelete={handleDeleteElseStep}
          onMoveUp={handleChildELSEControlMoveUp}
          onMoveDown={handleChildELSEControlMoveDown}
          onCopy={handleCopyElseStep}
        />
      );
      elseSteps.push(
        <AddStep
          key={uuid()}
          onAddStepClick={() => addElseStep(elseStepIndex)}
          isCompoundStatement={false}
        />
      );
    });

    const handleChangeOperation = (operation: string) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.operation = operation;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeArgument = (argument: string | string[] | undefined) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.argumentAssetId = argument;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeQuantity = (quantity: number) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.argumentQuantity = quantity;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeCheckbox = (isChecked: boolean) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;
      debugger;
      stp.useFallbackValue = isChecked;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleIdentifierChange = (identifier: string) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.identifier = identifier;
      this.setState({ ...this.state, selectedIdentifier: identifier });

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleIdentifierCompoundChange = (
      identifier: string,
      index: number
    ) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      this.setState({ ...this.state, selectedIdentifier: identifier });
      if (stp.ifCompoundStatements) {
        stp.ifCompoundStatements[index].identifier = identifier;

        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleConditionCompoundChange = (
      condition: string,
      index: number
    ) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      if (stp.ifCompoundStatements) {
        stp.ifCompoundStatements[index].condition = condition;

        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleOperationCompoundChange = (
      operation: string,
      index: number
    ) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      if (stp.ifCompoundStatements) {
        stp.ifCompoundStatements[index].operation = operation;
        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleArgumentmpoundChange = (argument: string, index: number) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;
      if (stp.ifCompoundStatements) {
        stp.ifCompoundStatements[index].argumentAssetId = argument;

        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleQuantityCompoundChange = (quantity: number, index: number) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      if (stp.ifCompoundStatements) {
        stp.ifCompoundStatements[index].argumentQuantity = quantity;

        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleDeleteCompoundChange = (index: number) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      if (stp.ifCompoundStatements) {
        stp.ifCompoundStatements.splice(index, 1);

        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleCopyCompoundStatement = (step: CompoundStatement) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      let newCompoundStep = cloneDeep(step);
      if (newCompoundStep) {
        newCompoundStep.id = uuid();

        if (!stp.ifCompoundStatements) {
          stp.ifCompoundStatements = [];
        }

        stp.ifCompoundStatements.push(newCompoundStep);
        this.handleDataChange(stp);
      }
    };

    const compoundStatementMove = (
      step: CompoundStatement,
      fromIndex: number,
      toIndex: number
    ) => {
      let stp: AlgorithmControlStructure = cloneDeep({
        ...gameStep
      }) as AlgorithmControlStructure;

      if (stp.ifCompoundStatements) {
        if (toIndex < 0) return;
        if (toIndex > stp.ifCompoundStatements.length - 1) return;
        stp.ifCompoundStatements.splice(fromIndex, 1);
        stp.ifCompoundStatements.splice(toIndex, 0, cloneDeep(step));

        if (this.handleDataChange) {
          this.handleDataChange(stp);
        }
      }
    };

    const handleConditionChange = (condition: string) => {
      let stp: AlgorithmControlStructure = {
        ...gameStep
      } as AlgorithmControlStructure;

      stp.condition = condition;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleDeleteStep = () => {
      if (this.handleDelete) {
        this.handleDelete(gameStep);
      }
    };

    const handleMoveUp = () => {
      if (this.handleMoveUp) {
        this.handleMoveUp(gameStep);
      }
    };

    const handleMoveDown = () => {
      if (this.handleMoveDown) {
        this.handleMoveDown(gameStep);
      }
    };

    const handleCopy = () => {
      if (this.handleCopy) {
        this.handleCopy(gameStep);
      }
    };

    return (
      <div
        className={
          'step-editor step-editor--control container-fluid mt-2 bg-light rounded p-2'
        }>
        <div className={'control__if row mt-2'}>
          <div className={'col'}>
            <div className={'d-flex align-items-center flex-wrap'}>
              <div className={'material-symbols-outlined text-code me-1'}>
                alt_route
              </div>
              <div className={'me-2 text-nowrap'}>if the</div>
              <IdentifierSelector
                identifier={identifier}
                onChange={handleIdentifierChange}
                action={this.props.action}
                isOnConditional={true}
              />
              <ConditionSelector
                condition={condition}
                onChange={handleConditionChange}
                selectedIdentifier={this.state.selectedIdentifier}
              />
              <OperationSelector
                operationsList={this.state.condtionalOperationList}
                operation={operation}
                argumentAssetId={argumentAssetId}
                argumentQuantity={argumentQuantity}
                onChangeOperation={handleChangeOperation}
                onChangeArgument={handleChangeArgument}
                onChangeQuantity={handleChangeQuantity}
                onChangeCheckbox={handleChangeCheckbox}
              />
            </div>
          </div>
          <div className={'col-auto'}>
            <div
              className={
                'material-symbols-outlined text-primary me-1 cursor-pointer'
              }
              style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
              title={'Move Down'}
              onClick={handleMoveDown}>
              move_down
            </div>
            <div
              className={
                'material-symbols-outlined text-primary me-1 cursor-pointer'
              }
              style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
              title={'Move Up'}
              onClick={handleMoveUp}>
              move_up
            </div>
            <div
              className={
                'material-symbols-outlined me-1 text-primary cursor-pointer'
              }
              style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
              title={'Copy'}
              onClick={handleCopy}>
              content_copy
            </div>
            <div
              className={
                'material-symbols-outlined me-1 text-danger cursor-pointer'
              }
              style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
              title={'Delete'}
              onClick={handleDeleteStep}>
              delete
            </div>
          </div>
        </div>

        {ifSteps}

        <div className={'control__else row mt-2'}>
          <div className={'col'}>
            <div className={'d-flex'}>
              <div className={'material-symbols-outlined text-code me-1'}>
                alt_route
              </div>
              <div>else</div>
            </div>
          </div>
        </div>

        {elseSteps}

        <div className={'control__end row mt-2 mb-2'}>
          <div className={'col'}>
            <div className={'d-flex'}>
              <div className={'material-symbols-outlined text-code me-1'}>
                alt_route
              </div>
              <div>end</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
