import { NodeSpec } from '@progress/kendo-react-editor';

const iframe: NodeSpec = {
  // NodeSpec interface
  // http://prosemirror.net/docs/ref/#model.NodeSpec
  attrs: {
    src: { default: null },
    width: { default: null },
    height: { default: null },
    style: { default: null }
  },
  group: 'block',
  selectable: false,
  parseDOM: [
    {
      tag: 'iframe',
      getAttrs: (dom: any) => ({
        src: dom.getAttribute('src'),
        width: dom.getAttribute('width'),
        height: dom.getAttribute('height'),
        style: dom.getAttribute('style')
      })
    }
  ],
  toDOM: (node) => {
    const attrs = {
      src: node.attrs.src,
      style: node.attrs.style,
      frameborder: '0',
      allowfullscreen: 'true',
      width: node.attrs.width,
      height: node.attrs.height
    };
    return ['iframe', attrs];
  }
};

const video: NodeSpec = {
  attrs: {
    src: { default: null },
    width: { default: null },
    height: { default: null },
    style: { default: null },
    align: { default: 'center' },
    fitToScreen: { default: false }
  },
  group: 'block',
  parseDOM: [
    {
      tag: 'video',
      getAttrs: (dom) => ({
        src: (dom as HTMLElement).getAttribute('src'),
        width: (dom as HTMLElement).getAttribute('width'),
        height: (dom as HTMLElement).getAttribute('height'),
        style: (dom as HTMLElement).getAttribute('style'),
        align: (dom as HTMLElement).getAttribute('align') || 'center',
        fitToScreen: (dom as HTMLElement).getAttribute('fitToScreen')
      })
    }
  ],
  toDOM: (node) => {
    const attrs = {
      src: node.attrs.src,
      controls: true,
      autoplay: false,
      width: node.attrs.fitToScreen ? '100%' : node.attrs.width,
      height: node.attrs.fitToScreen ? 'auto' : node.attrs.height,
      style: `${node.attrs.style} ${
        node.attrs.fitToScreen
          ? 'min-width:100%;min-height:auto;object-fit:cover;'
          : ''
      }`
    };
    return [
      'div',
      {
        style: `text-align: ${node.attrs.align}; ${
          node.attrs.fitToScreen
            ? 'width:100%;height:auto;overflow:hidden;'
            : ''
        }`
      },
      ['video', attrs]
    ];
  }
};

const audio: NodeSpec = {
  attrs: {
    src: { default: null },
    width: { default: null },
    height: { default: null },
    style: { default: null },
    align: { default: 'center' }
  },
  group: 'block',
  parseDOM: [
    {
      tag: 'audio',
      getAttrs: (dom) => ({
        src: (dom as HTMLElement).getAttribute('src'),
        style: (dom as HTMLElement).getAttribute('style'),
        align: (dom as HTMLElement).getAttribute('align') || 'center'
      })
    }
  ],
  toDOM: (node) => {
    const attrs = {
      src: node.attrs.src,
      controls: true,
      style: node.attrs.style
    };
    return [
      'div',
      { style: `text-align: ${node.attrs.align};` },
      ['audio', attrs]
    ];
  }
};

export { iframe, video, audio };
