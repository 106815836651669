import { GameDocument } from '../../types/game-document';
import { ResourceEntity } from '../../types/game-document/';
import {
  AddGameDocumentAsync,
  AddGameDocumentContentAsync,
  GetGameDocumentLatestAsync,
  GetPendingGameDocumentLatestAsync,
  PatchGameDocumentAsync,
  updateDocument
} from '../../services/json-document';
import { cloneDeep, isEmpty, merge } from 'lodash';
import semver, { ReleaseType } from 'semver';
import { GameDocumentState } from '../../contexts/game-document';
import { GetResourceEntity, UpdateResourceAsync } from './resources';
import { GetGameAsyncById, PatchGameAsync } from '../../services/games';
import { PostResourceAsync } from '../../services/files';
import { UpdateResourcePackResourceAsync } from './resource-packs';
import { uuid, uuid12 } from '../../types/common-helper';
import { formatDate } from '@progress/kendo-intl';
import { getBase64ImageExtension, getFileExtension } from '../files';

/**
 * Updates the Game Document for the provided gameId.
 *
 * @remarks
 *
 * The Game Document contains the name and description information for the Game
 * record. Therefore, this should also be updated in the database.
 *
 * Automatically updates the semver version of the Game Document by increasing
 * the patch number.
 *
 * @param gameId - The ID of the Game record
 * @param gameDocument - The updated game document to
 * @returns The updated Game Document
 */
export const UpdateGameDocumentAsync = async (
  gameId: number,
  gameDocument: GameDocument
) => {
  // 1. get document id by gameId [Get] games/{gameId}/documents/latest GetGameDocumentLatestAsync(state.gameId!);
  const documentLatest = await GetGameDocumentLatestAsync(gameId);
  const newVersion = await GetUpdatedVersionAsync(
    'patch',
    documentLatest.version
  );

  // Combine game details with new game document.
  let updatedGameDocument = merge(gameDocument, {
    version: newVersion
  });

  // create a filename prefix for resources.
  const dateString = formatDate(new Date(), 'yyMMdd');
  const fileNamePrefix = `${gameId}-${dateString}-${newVersion}`;

  // Move all blob urls to azure
  updatedGameDocument = await ExtractBlobResourcesAsync(
    fileNamePrefix,
    updatedGameDocument
  );

  // Move all base64 image from taskContent to Azure
  updatedGameDocument = await ExtractTaskContentImageAsync(
    fileNamePrefix,
    updatedGameDocument
  );

  // 3. patch library game by gameId [Patch] /games/{gameId} PatchGameAsync
  const patchData = {
    name: updatedGameDocument?.name,
    languages: updatedGameDocument.overview?.languages
  };
  await PatchGameAsync(gameId, patchData);

  // 4. post version document by gameId [Post] games/{gameId}/documents AddGameDocumentAsync
  const newGameDocument = await AddGameDocumentAsync(gameId, {
    ...documentLatest,
    version: newVersion!,
    fileName: `${fileNamePrefix}.json`
  });

  // 5. update document content AddGameDocumentContentAsync
  await AddGameDocumentContentAsync(
    gameId,
    newGameDocument.id!,
    updatedGameDocument
  );

  return updatedGameDocument;
};

/**
 * Publishes the Game Document for the provided gameId to the global library.
 *
 * @remarks
 *
 * You should only publish a saved draft, so this function does not manipulate blobs.
 *
 * Automatically updates the semver version of the Game Document by increasing
 * the patch number.
 *
 * @param gameId - The ID of the Game record
 * @param gameDocument - The game document to publish
 * @returns The published Game Document
 */
export const PublishGameDocumentAsync = async (
  gameId: number,
  gameDocument: GameDocument
) => {
  // Load the game details from the database.
  let game = await GetGameAsyncById(gameId);

  // Update the current version
  let newVersion = await GetUpdatedVersionAsync('minor', gameDocument.version);

  //Get latest document
  let latestDocument = await GetGameDocumentLatestAsync(gameId);

  // Combine game details with new game document.
  let updatedGameDocument = merge(gameDocument, {
    version: newVersion
  });

  // Update the Game Document (with the new minor version).
  await updateDocument(gameId, latestDocument.id!, updatedGameDocument);

  await PatchGameDocumentAsync(latestDocument?.gameId!, latestDocument?.id!, {
    id: latestDocument?.id,
    gameId: latestDocument?.gameId,
    status: 'Published',
    version: newVersion ?? '',
    fileName: latestDocument?.fileName
  });

  return updatedGameDocument;
};

/**
 * Submit to global library the Game Document for the provided gameId to the global library.
 *
 * @remarks
 *
 * You should only publish a saved draft, so this function does not manipulate blobs.
 *
 * Automatically updates the semver version of the Game Document by increasing
 * the patch number.
 *
 * @param gameId - The ID of the Game record
 * @param gameDocument - The game document to publish
 * @returns The published Game Document
 */
export const SubmitToGlobalLibraryGameDocumentAsync = async (
  gameId: number,
  gameDocument: GameDocument
) => {
  // Load the game details from the database.
  let game = await GetGameAsyncById(gameId);

  // Update the current version
  let newVersion = await GetUpdatedVersionAsync('minor', gameDocument.version);

  //Get latest document
  let latestDocument = await GetGameDocumentLatestAsync(gameId);

  // Combine game details with new game document.
  let updatedGameDocument = merge(gameDocument, {
    version: newVersion
  });

  // Update the Game Document (with the new minor version).
  await updateDocument(gameId, latestDocument.id!, updatedGameDocument);

  await PatchGameDocumentAsync(latestDocument?.gameId!, latestDocument?.id!, {
    id: latestDocument?.id,
    gameId: latestDocument?.gameId,
    status: 'Pending',
    version: newVersion ?? '',
    fileName: latestDocument?.fileName
  });

  return updatedGameDocument;
};

/**
 * Approve/Reject the Game Document for the provided gameId.
 * @param gameId - The ID of the Game record
 * @param status - The Status Approved/Rejected
 * @returns The published Game Document
 */
export const ApprovalDocumentAsync = async (
  gameId: number,
  status: 'Approved' | 'Rejected',
  isGlobal: boolean
) => {
  //Get latest document
  let latestDocument = await GetPendingGameDocumentLatestAsync(gameId);

  await PatchGameDocumentAsync(latestDocument?.gameId!, latestDocument?.id!, {
    id: latestDocument?.id,
    gameId: latestDocument?.gameId,
    status: status,
    isGlobal: isGlobal,
    version: latestDocument?.version,
    fileName: latestDocument?.fileName
  });
};

/**
 * Creates a new semver version number based on the release type.
 * @param type - The type of version increment [release]
 * @param currentVersion - The current version to be updated
 * @returns The new version number
 */
export const GetUpdatedVersionAsync = async (
  type: ReleaseType,
  currentVersion?: string
) => {
  return semver.inc(currentVersion ?? '0.0.0', type);
};

/**
 * Extracts all blob resources from the game document, uploads them to azure
 * and replaces the resource urls.
 *
 * @param {string} filenamePrefix - The prefix to use for the extracted image filenames.
 * @param gameDocument - The game document to extract the blob resources from
 */
export const ExtractBlobResourcesAsync = async (
  filenamePrefix: string,
  gameDocument: GameDocument
) => {
  let resources = await uploadBlobResourceAsync(
    filenamePrefix,
    gameDocument,
    gameDocument?.resources!,
    ''
  );

  if (resources) {
    gameDocument = resources;
  }

  gameDocument?.resourcePacks?.forEach(async (resourcePack) => {
    let resourcePacks = await uploadBlobResourceAsync(
      filenamePrefix,
      gameDocument,
      resourcePack?.resources!,
      resourcePack?.name
    );
    if (resourcePacks) {
      gameDocument = resourcePacks;
    }
  });

  return gameDocument;
};

const uploadBlobResourceAsync = async (
  filenamePrefix: string,
  gameDocument: GameDocument,
  resourceEntity: ResourceEntity[],
  resourcePackName: string
) => {
  for (const resource of resourceEntity) {
    if (
      resource.value && typeof resource.value === 'string'
        ? resource.value.startsWith('blob:')
        : false
    ) {
      try {
        if (resource.value !== undefined) {
          // load the ACTUAL file data from the BLOB url.
          const blob = await fetch(resource.value).then((r) => r.blob());
          const fileName = `${filenamePrefix}-${uuid12()}.${getFileExtension(
            resource.description
          )}`;
          const extractedBlob: File = new File([blob], fileName, {
            type: blob.type
          });

          // upload the file to azure blob
          // TODO: Big File Support?
          const newResourceResponse = await PostResourceAsync(extractedBlob);
          resource.value = newResourceResponse.value ?? '';

          // check response API that will return thumbnail URL or not
          if (
            newResourceResponse.thumbnailValue !== undefined &&
            newResourceResponse.thumbnailValue !== ''
          ) {
            // Add new resource for thumbnail
            const thumbnailResourceId = uuid();
            gameDocument.resources.push({
              id: thumbnailResourceId,
              value: newResourceResponse.thumbnailValue,
              type: 'image'
            } as ResourceEntity);
            // update thumbnailId on media
            const updateMedia = gameDocument.overview?.medias.map((obj) => {
              if (obj.value === resource.id) {
                obj.thumbnailValue = thumbnailResourceId;
              }
              return obj;
            });
            gameDocument.overview!.medias! = updateMedia!;
          }

          if (resourcePackName === '') {
            gameDocument = await UpdateResourceAsync(
              gameDocument,
              resource.id!,
              resource as ResourceEntity
            );
          } else {
            let resourcePack = await UpdateResourcePackResourceAsync(
              gameDocument,
              resourcePackName,
              resource.id!,
              resource
            );

            if (resourcePack) {
              gameDocument = resourcePack;
            }
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  }
  return gameDocument;
};

/**
 * Upload single blob
 * @param url - The local blob URL
 * @returns Resource entity
 */
export const UploadSingleBlobAsync = async (url: string) => {
  if (url.startsWith('blob:')) {
    try {
      // load the ACTUAL file data from the BLOB url.
      let blobResponse = await fetch(url);
      let blobContent = await blobResponse.blob();

      // upload the file to azure blob
      // TODO: Big File Support?
      const newResource = (await PostResourceAsync(
        blobContent
      )) as ResourceEntity;
      return newResource;
    } catch (ex) {
      console.error(ex);
    }
  }
};

export const UpdateGameSettingsAsync = async (
  gameId: number,
  gameDocument: GameDocument
) => {
  // 1. get document id by gameId [Get] games/{gameId}/documents/latest GetGameDocumentLatestAsync(state.gameId!);
  const documentLatest = await GetGameDocumentLatestAsync(gameId);
  const newVersion = await GetUpdatedVersionAsync(
    'patch',
    documentLatest.version
  );

  // Combine game details with new game document.
  let updatedGameDocument = merge(gameDocument, {
    version: newVersion
  });

  // 4. post version document by gameId [Post] games/{gameId}/documents AddGameDocumentAsync
  // get date with yymmdd format
  const date = new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')
    .substring(2);
  const newGameDocument = await AddGameDocumentAsync(gameId, {
    ...documentLatest,
    version: newVersion!,
    fileName: `${gameId}-${date}-${newVersion}.json`
  });

  // 5. update document content AddGameDocumentContentAsync
  await AddGameDocumentContentAsync(
    gameId,
    newGameDocument.id!,
    updatedGameDocument
  );

  return updatedGameDocument;
};

export const CleanMapIllustrationArea = (gameDocument: GameDocument) => {
  const cleanedGameDocument = cloneDeep(gameDocument);
  cleanedGameDocument.rules.worldMap.zones.forEach((zone) => {
    const zoneMap = cleanedGameDocument.assets.maps?.find(
      (map) => map.id === zone.mapAssId
    );
    if (zoneMap && zoneMap.type === 'illustration') {
      const zoneAsset = cleanedGameDocument.assets.zones?.find(
        (assetZone) => assetZone.id === zone.zoneAssId
      );
      if (zoneAsset && !isEmpty(zoneAsset.areas)) {
        const ids = zoneAsset.areas?.map((o) => o.areaAssId);
        const filteredArea = cleanedGameDocument.assets.areas?.filter(
          (o) => !ids?.includes(o.id)
        );
        cleanedGameDocument.assets.areas = filteredArea;
        zoneAsset.areas = [];
      }
    }
  });

  return cleanedGameDocument;
};

/**
 * ExtractTaskContentImageAsync takes a filename prefix and a game document, and extracts
 * base64 images from task content and pre messages in the game document. It returns
 * the updated game document with the replaced resources.
 *
 * @param {string} filenamePrefix - The prefix to use for the extracted image filenames.
 * @param {GameDocument} gameDocument - The game document to be updated.
 * @returns {Promise<GameDocument>} - The updated game document.
 */
const ExtractTaskContentImageAsync = async (
  filenamePrefix: string,
  gameDocument: GameDocument
) => {
  // exit early if no taskContents in the game document.
  if (!gameDocument.assets.taskContents) return gameDocument;

  // create a working copy of the resources to merge back in ad the end.
  const resources = gameDocument.resources;

  // loop all task content entities and extract base64 images from the task content and pre message
  for (const taskContent of gameDocument.assets.taskContents) {
    await ExtractResourceBase64ImagesByIdAsync(
      filenamePrefix,
      gameDocument,
      resources,
      taskContent.contentResId
    );
    await ExtractResourceBase64ImagesByIdAsync(
      filenamePrefix,
      gameDocument,
      resources,
      taskContent.preMessageResId
    );
  }

  // return the updated game document with the replaced resources.
  return merge(gameDocument, { resources });
};

/**
 * Extracts base64 images from a resource by ID and updates the resource in the provided array.
 * @param {string} filenamePrefix - The prefix to use for the extracted files.
 * @param {GameDocument} gameDocument - The game document.
 * @param {ResourceEntity[]} resources - The array of resource entities.
 * @param {string} [resourceId] - The ID of the resource to extract images from.
 * @returns {Promise<void>} - A promise that resolves once the extraction and update is complete.
 */
const ExtractResourceBase64ImagesByIdAsync = async (
  filenamePrefix: string,
  gameDocument: GameDocument,
  resources: ResourceEntity[],
  resourceId?: string
) => {
  // exit early if we don't have a resource id.
  if (!resourceId) return;

  // exit if we cant find the resource.
  const resource = GetResourceEntity(gameDocument, resourceId);
  if (!resource) return;

  // extract the base 64 images from the resource.
  const updatedResource = await ExtractResourceBase64ImagesAsync(
    filenamePrefix,
    resource
  );

  // this shouldn't happen.
  if (!updatedResource) return;

  // replace the resource
  let resourceIndex = resources?.findIndex((x) => x.id === updatedResource.id);
  if (resourceIndex > -1) resources[resourceIndex] = updatedResource;
};

/**
 * Extracts base64 images from a resource entity asynchronously.
 * @param {string} filenamePrefix - The prefix to use for the extracted files.
 * @param {ResourceEntity} resource - The resource entity containing the value to extract images from.
 * @returns {Promise<ResourceEntity>} - A promise that resolves with the modified resource entity.
 */
const ExtractResourceBase64ImagesAsync = async (
  filenamePrefix: string,
  resource: ResourceEntity
) => {
  if (!resource.value) return;
  let value = resource.value as string;
  const base64Images = GetResourceBase64Images(value);
  for (const base64Image of base64Images) {
    const fileName = `${filenamePrefix}-${uuid12()}.${getBase64ImageExtension(
      base64Image
    )}`;
    const blob = await fetch(base64Image).then((r) => r.blob());
    const extractedBlob: File = new File([blob], fileName, {
      type: blob.type
    });
    const resourceResponse = await PostResourceAsync(extractedBlob);
    value = value.replace(base64Image, resourceResponse.value);
  }
  return { ...resource, value };
};

/**
 * Global Regular Expression for locating base64 data urls in strings.
 * Note: This should not contain any capturing groups and work with both markdown and html notation. e.g
 * Markdown = (data:image/jpeg;base64,x0aaaaa)
 * Html = "data:image/jpeg;base64,x0aaaaa"
 */
const base64ImageRegExp = /data:image\/[^;]+;base64[^")]+/g;

/**
 * Extracts all base64 images from the given string value.
 *
 * @param {string} value - The string value to search for base64 images.
 * @returns {Array<string>} - Array of base64 image strings.
 */
const GetResourceBase64Images = (value: string) => {
  const dataUrls = value.matchAll(base64ImageRegExp);
  let items = new Array<string>();
  if (dataUrls) {
    for (const dataUrl of dataUrls) {
      items = [...items, dataUrl[0]];
    }
  }
  return items;
};

export * from './assets';
export * from './resources';

export const UpdateGameDocState = (
  state: GameDocumentState,
  updated: GameDocument,
  isDirty: boolean = true
) => {
  return cloneDeep({
    ...state,
    isDirty,
    gameDocument: cloneDeep({ ...updated })
  });
};

/**
 * get total game size
 * @param gameDocument - The Game Document to check resources
 * @returns number
 */
export const GetTotalGameSize = (gameDocument: GameDocument): number => {
  const jsonString = JSON.stringify(gameDocument);
  const bytes = new TextEncoder().encode(jsonString);
  const resourcesImage = gameDocument.resources.filter(
    (resources) =>
      (resources.type === 'image' || resources.type === 'video') &&
      resources.size !== undefined
  );

  const totalResourceImageSize =
    resourcesImage.length > 0
      ? resourcesImage.reduce((acc, item) => acc + item.size!, 0)
      : 0;
  return bytes.length + totalResourceImageSize;
};
