import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { Col, Row } from 'react-bootstrap';
import { ScoringReductionEntity } from '../../types/game-document/entities/task-content';

type ScoringReductionProps = {
  isDisabled: boolean;
  isScoringReduction?: boolean;
  scoringReductionState?: ScoringReductionEntity;
  errorMessage?: string;
  onChangeScoringReductionCheckbox: () => void;
  onChangeScoringReductionInput: (
    scoringReductionState?: ScoringReductionEntity
  ) => void;
};
export function ScoringReductionAnswer({
  isDisabled,
  isScoringReduction,
  scoringReductionState,
  errorMessage,
  onChangeScoringReductionCheckbox = () => {},
  onChangeScoringReductionInput = () => {}
}: ScoringReductionProps) {
  const onChangeScoringReduction = (
    field?: keyof ScoringReductionEntity,
    value?: number
  ) => {
    let newScoringReductionState: ScoringReductionEntity = {
      ...scoringReductionState,
      [`${field}`]: value
    };
    onChangeScoringReductionInput(newScoringReductionState);
  };

  return (
    <>
      <Row className={'d-flex justify-content-between'}>
        <Col md={'6'}></Col>
        <Col sm={'12'} md={'6'} lg={'6'} xl={'6'}>
          <Checkbox
            id={'checkbox-scoring-reduction'}
            disabled={isDisabled}
            label={'Scoring reduction'}
            checked={isScoringReduction}
            onChange={onChangeScoringReductionCheckbox}></Checkbox>
        </Col>
      </Row>
      {isScoringReduction && (
        <Row>
          <Col sm={12} md={12} lg={6} xl={6}>
            <Row className={'d-flex align-items-center mt-2 p-1'}>
              <Col sm={'12'} md={'12'} lg={'12'} xl={'12'}>
                <span className={'mr-2'}>Maximum time to answer question</span>
                <NumericTextBox
                  className={'mr-2'}
                  width={170}
                  max={999}
                  min={0}
                  value={scoringReductionState?.maximumTimeLimit as number}
                  onChange={(e) =>
                    onChangeScoringReduction(
                      'maximumTimeLimit',
                      e.target.value as number
                    )
                  }
                />
                <span className={'mr-2'}>Seconds</span>
              </Col>
            </Row>
            <Row>
              <Col className={'ml-1'}>
                {!scoringReductionState?.maximumTimeLimit && errorMessage && (
                  <Error>{errorMessage}</Error>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={12} lg={6} xl={6}>
            <Row className={'d-flex align-items-center mt-2 p-1'}>
              <Col sm={'12'} md={'12'} lg={'12'} xl={'12'}>
                <span className={'mr-2'}>Reduce points awarded by</span>
                <NumericTextBox
                  className={'mr-2'}
                  value={scoringReductionState?.scoreReduction as number}
                  width={170}
                  min={0}
                  onChange={(e) =>
                    onChangeScoringReduction(
                      'scoreReduction',
                      e.target.value as number
                    )
                  }
                />
                <span className={'mr-2'}>Point</span>
              </Col>
            </Row>
            <Row>
              <Col className={'ml-1'}>
                {!scoringReductionState?.scoreReduction &&
                  scoringReductionState?.scoreReduction !== 0 &&
                  errorMessage && <Error>{errorMessage}</Error>}
              </Col>
            </Row>
            <Row className={'d-flex align-items-center mt-2 p-1'}>
              <Col sm={'12'} md={'12'} lg={'12'} xl={'12'}>
                <span className={'mr-2'}>Every</span>
                <NumericTextBox
                  className={'mr-2'}
                  value={
                    scoringReductionState?.scoreReductionInterval as number
                  }
                  width={170}
                  min={0}
                  onChange={(e) =>
                    onChangeScoringReduction(
                      'scoreReductionInterval',
                      e.target.value as number
                    )
                  }
                />
                <span className={'mr-2'}>Seconds</span>
              </Col>
            </Row>
            <Row>
              <Col className={'ml-1'}>
                {!scoringReductionState?.scoreReductionInterval &&
                  errorMessage && <Error>{errorMessage}</Error>}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}
