import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { GetEventsAsAdmin } from '../services/events';
import { ColumnMenu } from '../components/columnMenu';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { formatDateToDDMMYYYY } from '../utils/date';
import EventsScheduler from './events-scheduler';

const initialDataState: State = {
  sort: [{ field: 'startDateUtc', dir: 'desc' }],
  filter: DefaultGridSettings.initialDataState.filter,
  skip: DefaultGridSettings.initialDataState.skip,
  group: DefaultGridSettings.initialDataState.group
};

const EventsManagementGrid = () => {
  const [dataState, setDataState] = useState<State>({
    ...initialDataState,
    take: DefaultGridSettings.initialDataState.take
  });
  const [events, setEvents] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));
  const [gridMode, setGridMode] = useState<string>('grid');

  const fetchEvents = async () => {
    GetEventsAsAdmin(`${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        setEvents(response);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    fetchEvents();
    //eslint-disable-next-line
  }, [dataState]);

  const gameColumn = (props: GridCellProps) => {
    return (
      <td>
        <span>
          {props.dataItem.game ? 'Game Template' : 'Assessment Template'}
        </span>
      </td>
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.startDateUtc, true)}</td>;
  };

  return (
    <>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search events'}
          columnsToSearch={['name', 'organisation']}
          showCalendarMode={true}
          showCardMode={false}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            const newState = {
              ...initialDataState
            };
            if (e.gridMode === 'grid') {
              newState.take = DefaultGridSettings.initialDataState.take;
            }
            setDataState(newState);
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={events.data}
          ref={gridExportRef}
          fileName={`all-events.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-events'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={events}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'startDateUtc'}
                title={'Start date'}
                cell={DateCell}
              />
              <Column field={'endDateUtc'} title={'End date'} cell={DateCell} />
              <Column field={'name'} title={'Name'} columnMenu={ColumnMenu} />
              <Column cell={gameColumn} title={'Game'} />
              <Column
                field={'organisation'}
                title={'Organisation'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'approxPax'}
                title={'Pax'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'status'}
                title={'Status'}
                columnMenu={ColumnMenu}
              />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'cal' && <EventsScheduler eventData={events} />}
        </ExcelExport>
      </div>
    </>
  );
};
export default EventsManagementGrid;
