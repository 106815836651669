import { GameDocumentState } from '../../contexts/game-document';
import {
  NumericTextBox,
  RadioButton,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { useMemo, useState } from 'react';
import { UpdateInGameSettingAsync } from '../../utils/game-document/settings';
import { Designer, InGame } from '../../constants/settings';
import { GameDocument } from '../../types/game-document';
import cloneDeep from 'lodash.clonedeep';

interface InGameSettingsProps {
  state: GameDocumentState;
  onChange: (gameDocument: GameDocument) => void;
}

const InGameSettings = (props: InGameSettingsProps) => {
  const { state, onChange } = props;
  const [, setIsEnableLeaderboard] = useState<boolean>(false);

  const handleInGameSettingChange = (e: RadioButtonChangeEvent) => {
    let inGameSetting = state.gameDocument?.settings.inGame!;
    UpdateInGameSettingAsync(state.gameDocument!, {
      ...inGameSetting,
      [e.target.element?.name!]: e.value
    }).then((updatedGameDocument) => {
      onChange(updatedGameDocument);
    });

    if (e.target.element?.name === InGame.leaderboard) {
      setIsEnableLeaderboard(e.value);
    }
  };

  //the logic for this setting is using IsShow, so the 'Show' Radio button will send "true" value, and for the inital value set on false (Hide)
  return (
    <>
      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Game information tab</span>
        <RadioButton
          name={InGame.information}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameInfo === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.information}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameInfo === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of game info tab in the menu.
        </span>
      </div>

      <hr />
      {/* Temporary Hide this feature related ticket: #4890
      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Player/Team map positions</span>
        <RadioButton
          name={InGame.playerTeamMapPositions}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame
              .showInGamePlayerTeamMapPositions === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.playerTeamMapPositions}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame
              .showInGamePlayerTeamMapPositions === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of player/team name on the game map.
        </span>
      </div>
      <hr />
      */}

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Team chat</span>
        <RadioButton
          name={InGame.teamChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTeamChat === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.teamChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTeamChat === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Ability of teams to message other teams.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>All players chat</span>
        <RadioButton
          name={InGame.allPlayerChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameAllPlayerChat === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.allPlayerChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameAllPlayerChat ===
              false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Ability of players/teams to message all players in the game.
        </span>
      </div>

      <hr />

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Scoreboard</span>
        <RadioButton
          name={InGame.scoreboard}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameScoreboard === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.scoreboard}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameScoreboard === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of all player/team scores/progress in the game.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Player / Team score</span>
        <RadioButton
          name={InGame.playerTeamScore}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGamePlayerTeamScore ===
              true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.playerTeamScore}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGamePlayerTeamScore ===
              false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of player/team own score/progress in the game.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Tasks require proximity</span>
        <RadioButton
          name={InGame.tasksRequireProximity}
          value={true}
          label={'True'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.inGame.tasksRequireProximity === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.tasksRequireProximity}
          value={false}
          label={'False'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.inGame.tasksRequireProximity === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Players need to be within 25 meters of a task before they can activate
          (open) it
        </span>
      </div>

      <div>
        <div className={'d-flex gap-1 align-items-center mt-2 w-100'}>
          <span className={'mr-10 w-30'}>Set task proximity</span>
          <NumericTextBox
            name={InGame.setTaskProximity}
            className={'mr-1 w-15'}
            value={state?.gameDocument?.settings.inGame.setTaskProximity ?? 0}
            onChange={handleInGameSettingChange}
            min={0}
          />
          <span className={'w-30'}>Meters</span>
        </div>

        <div className={'d-flex gap-1 align-items-center mb-2'}>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            {`Default task proximity is ${state?.gameDocument?.settings.inGame.setTaskProximity ?? 25} m.`}
          </span>
        </div>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Task title visibility</span>
        <RadioButton
          name={InGame.showTasknameVisibility}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.inGame.showTasknameVisibility
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showTasknameVisibility}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument.settings.inGame.showTasknameVisibility
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Task title visibility
        </span>
      </div>

      {/* settings for players on map */}
      <hr />

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Other Players/Teams</span>
        <RadioButton
          name={InGame.showTeamOrPlayerNameOnMap}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showTeamOrPlayerNameOnMap ===
              true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showTeamOrPlayerNameOnMap}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showTeamOrPlayerNameOnMap ===
              false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of other players/teams on the map.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Player score</span>
        <RadioButton
          name={InGame.showPlayerScoreOnMap}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showPlayerScoreOnMap === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showPlayerScoreOnMap}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showPlayerScoreOnMap === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of player score on the map.
        </span>
      </div>
      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Show detail on click</span>
        <RadioButton
          name={InGame.showPlayerDetailByClickOnMap}
          value={true}
          label={'On'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showPlayerDetailByClickOnMap ===
              true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showPlayerDetailByClickOnMap}
          value={false}
          label={'Off'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showPlayerDetailByClickOnMap ===
              false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of player detail by click on the map.
        </span>
      </div>
    </>
  );
};

export default InGameSettings;
