import { GameDocument } from '../../../types/game-document';
import { DesignerSettings } from '../../../types/game-document/settings';
import { uuid } from '../../../types/common-helper';
import { MergeGameSettings } from './index';
import { ResourceEntity } from '../../../types/game-document/entities';
import {
  AddResourceAsync,
  AddResourceEntityAsync,
  GetResourceByName,
  UpdateResourceAsync
} from '../resources';
import { AllCorrectCustomFeedback, AllWrongCustomFeedback } from '../assets';

/**
 * Adds a new Setting to the Game document.
 * @param gameDocument - The Game Document to modify
 * @returns The updated Game Document
 */
export const AddDesignerSettingAsync = async (gameDocument: GameDocument) => {
  let updatedGameDocument = gameDocument;
  const designerSettingResource: ResourceEntity[] = [
    {
      id: uuid(),
      name: 'Designer Setting - default available task icon',
      description: "The game's default available task icon",
      value: 'https://cdn.catalystglobal.games/resources/map-task.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default completed task icon',
      description: "The game's default completed task icon",
      value:
        'https://cdn.catalystglobal.games/resources/map-task--complete.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default feedback all correct icon',
      description: "The game's default completed feedback all correct icon",
      value:
        'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/checked.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default feedback all wrong icon',
      description: "The game's default completed feedback all wrong icon",
      value:
        'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/cross.png',
      type: 'text'
    }
  ];

  for (const res of designerSettingResource) {
    updatedGameDocument = await AddResourceEntityAsync(gameDocument, res);
  }

  const defaultAllCorrectFeedbackMessageResId =
    await DefaultAllCorrectFeedbackMessageResource(
      gameDocument,
      'All correct feedback message default'
    );
  const defaultAllWrongFeedbackMessageResId =
    await DefaultAllWrongFeedbackMessageResource(
      gameDocument,
      'All wrong feedback message default'
    );

  const designerSetting = {
    taskCompletionVisibility: true,
    defaultTaskAvailableIconResId: designerSettingResource[0].id,
    defaultTaskCompletedIconResId: designerSettingResource[1].id,
    defaultFeedbackAllCorrectIconResId: designerSettingResource[2].id,
    defaultFeedbackAllWrongIconResId: designerSettingResource[3].id,
    defaultAllCorrectFeedbackMessageResId,
    defaultAllWrongFeedbackMessageResId
  };

  return MergeGameSettings(gameDocument, designerSetting, 'designer');
};

export const DefaultAllCorrectFeedbackMessageResource = async (
  gameDocument: GameDocument,
  message: string
) => {
  let allCorrectMessageResource = await GetResourceByName(
    gameDocument!,
    'All correct feedback message default'
  );

  const defaultAllCorrectFeedbackMessageResId = allCorrectMessageResource
    ? allCorrectMessageResource.id
    : uuid();

  if (!allCorrectMessageResource) {
    await AllCorrectCustomFeedback(
      gameDocument,
      message,
      defaultAllCorrectFeedbackMessageResId
    );
  }

  if (allCorrectMessageResource && !allCorrectMessageResource.value) {
    const updatedResource = { ...allCorrectMessageResource, value: message };
    await UpdateResourceAsync(
      gameDocument,
      allCorrectMessageResource.id,
      updatedResource
    );
  }

  if (!allCorrectMessageResource) {
    await AddResourceAsync(
      gameDocument,
      'All correct feedback message default',
      'All correct feedback message default',
      'text',
      message,
      defaultAllCorrectFeedbackMessageResId
    );
  }

  return defaultAllCorrectFeedbackMessageResId;
};

export const DefaultAllWrongFeedbackMessageResource = async (
  gameDocument: GameDocument,
  message: string
) => {
  let allWrongMessageResource = await GetResourceByName(
    gameDocument!,
    'All wrong feedback message default'
  );

  const defaultAllWrongFeedbackMessageResId = allWrongMessageResource
    ? allWrongMessageResource.id
    : uuid();

  if (!allWrongMessageResource) {
    await AllWrongCustomFeedback(
      gameDocument,
      message,
      defaultAllWrongFeedbackMessageResId
    );
  }

  if (allWrongMessageResource && !allWrongMessageResource.value) {
    const updatedResource = { ...allWrongMessageResource, value: message };
    await UpdateResourceAsync(
      gameDocument,
      allWrongMessageResource.id,
      updatedResource
    );
  }

  if (!allWrongMessageResource) {
    await AddResourceAsync(
      gameDocument,
      'All wrong feedback message default',
      'All wrong feedback message default',
      'text',
      message,
      defaultAllWrongFeedbackMessageResId
    );
  }

  return defaultAllWrongFeedbackMessageResId;
};

/**
 * Updates the identified Setting in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param designerSetting - The new designer settings
 * @constructor
 */
export const UpdateDesignerSettingAsync = async (
  gameDocument: GameDocument,
  designerSetting: DesignerSettings
) => {
  return MergeGameSettings(gameDocument, designerSetting, 'designer');
};
