import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { MapImage } from '../../../types/game-document';
import { FluidForm, StandardInput } from '../../../components/forms';
import { useContext, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import {
  InputChangeEvent,
  Slider,
  SliderChangeEvent,
  SliderLabel
} from '@progress/kendo-react-inputs';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import RequiredFields from '../../../types/required-fields';
import { Button } from '@progress/kendo-react-buttons';
import CoverImage from '../../../components/cover-image';
import PopupMenu from '../../../components/popupMenu';
import { ResourceEntity } from '../../../types/game-document/';
import {
  AddResourceAsync,
  GetResourceById,
  UpdateGameDocState,
  UpdateResourceAsync
} from '../../../utils/game-document';
import { uuid } from '../../../types/common-helper';
import { GameDocumentContext } from '../../../contexts/game-document';
import {
  ResourceWindow,
  UploadedImage
} from '../image-resource/resource-window';
import { Error } from '@progress/kendo-react-labels';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';

interface MapImageWindowProps {
  editorEntity: EntityEditor<MapImage>;
  editorResource: EntityEditor<ResourceEntity>[];

  toggleDialog: Function;
  onSubmit: (editorEntity: EntityEditor<MapImage>) => void;
  mapImages?: MapImage[];
}

export interface MenuList {
  classIcon?: string;
  textMenu?: string;
  textClass?: string;
}

export const MapImageWindow = ({
  editorEntity,
  editorResource,
  onSubmit,
  mapImages,
  toggleDialog,
  ...props
}: MapImageWindowProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const gameDocumentFiles = useGameDocumentResources();
  const [entity, setEntity] = useState<MapImage>(
    cloneDeep(editorEntity.entity)
  );
  const [imageUrl, setImageUrl] = useState<string>(
    editorEntity?.entity?.imageResId && state.gameDocument
      ? GetResourceById(state.gameDocument, editorEntity.entity.imageResId)
          ?.value ?? ''
      : ''
  );
  const [dialogUploadVisible, setDialogUploadVisible] =
    useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<string>('');
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [showDeleteIconConfirm, setShowDeleteIconConfirm] =
    useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<UploadedImage>();
  const taskMenu: MenuList[] = [
    { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
    { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
  ];
  const [resources, setResources] = useState<ResourceEntity[]>(
    editorResource?.length > 0
      ? [...editorResource?.map((task) => task.entity)]
      : []
  );
  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    if (entity.titleResId) {
      setResources((prev) =>
        prev.map((resource: ResourceEntity) =>
          resource.id === entity['titleResId']
            ? { ...resource, value: value as string, name: name ?? '' }
            : { ...resource }
        )
      );

      let imageResource = GetResourceById(
        state?.gameDocument!,
        entity?.titleResId
      );
      if (imageResource) {
        imageResource.value = value as string;
        UpdateResourceAsync(
          state?.gameDocument!,
          entity.titleResId,
          imageResource
        ).then((response) => {});
      }

      setEntity({ ...entity, [name!]: value });
    } else {
      const titleGuid = uuid();
      const addTitleResource = AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        event.target.value as string,
        titleGuid
      );
      setEntity({
        ...entity,
        [name!]: value,
        titleResId: titleGuid,
        isVisible: true
      });
    }
  };

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<MapImage>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'opacity', errorMessage: '' },
    { name: 'imageResId', errorMessage: '' }
  ]);

  const [opacityValue, setOpacityValue] = useState(
    (editorEntity?.entity?.opacity ?? 1) * 100 ?? 100
  );

  const handleOpacityChange = (event: SliderChangeEvent) => {
    setOpacityValue(event.value);
    const adjustmentOpacityValue = event.value / 100;
    setEntity((prev) => ({
      ...prev,
      opacity: adjustmentOpacityValue
    }));
  };

  const handleImageSelected =
    (field: string) => (id: number, menu: MenuList) => {
      if (field === 'availableImage' && menu.textMenu === 'Edit') {
        setDialogUploadVisible(true);
        setFileUpload('imageResId');
        setSelectedImageUrl(imageUrl);
      } else if (field === 'availableImage' && menu.textMenu === 'Delete') {
        setImageUrl('');
      }
    };

  const updateMapResource = async (newImage: UploadedImage) => {
    setUploadedImage(newImage);
    setDialogUploadVisible(false);
    setImageUrl(newImage?.blobUrl);
    if (newImage?.blobUrl) {
      let newRequireFields = cloneDeep(requiredFields);
      newRequireFields[newRequireFields.length - 1].errorMessage = '';
      setRequiredFields(newRequireFields);
    }
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map(
      (input: RequiredFields<MapImage>) => {
        const entityValue = entity[input.name as keyof MapImage];
        const itemIndex = mapImages?.findIndex(
          (item) => item.name === entityValue
        );

        input.errorMessage = '';

        if (input.name === 'name') {
          if (!entityValue || entityValue === '') {
            input.errorMessage = 'This field is required';
          }
          if (itemIndex! > -1 && mapImages![itemIndex!]?.id !== entity.id) {
            input.errorMessage = 'Please enter unique name';
          }
        } else if (input.name === 'imageResId') {
          if (imageUrl === '') {
            input.errorMessage = 'This field is required';
          }
        }

        return input;
      }
    );
    setRequiredFields(inputValidation);

    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  return (
    <Window
      modal={true}
      onClose={() => toggleDialog()}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={editorEntity.isNew ? 600 : 700}
      initialHeight={editorEntity.isNew ? 550 : 650}
      title={editorEntity.isNew ? 'New Image' : editorEntity.entity.name}
      {...props}>
      <FluidForm>
        <div className={'d-flex row'}>
          <div className="col col-4">
            <label>Map Image</label>
            <CoverImage
              containerStyle={'square'}
              imageUrl={imageUrl}
              opacity={entity?.opacity ?? 100}>
              <div className={'mr-3 mt-1'}>
                <PopupMenu
                  id={0}
                  menus={taskMenu}
                  onMenuSelected={handleImageSelected('availableImage')}
                />
              </div>
            </CoverImage>
            <Error>
              {
                requiredFields?.find((item) => item.name === 'imageResId')
                  ?.errorMessage
              }
            </Error>
          </div>
          <div className="col col-8">
            <div className={'flex-column flex-fill'}>
              <StandardInput
                name={'name'}
                label={'Name'}
                value={entity?.name}
                onChange={handleInputChange}
                validationMessage={
                  requiredFields?.find((item) => item.name === 'name')
                    ?.errorMessage
                }
                autoFocus={true}
              />
              <div className={'d-flex flex-column mt-2'}>
                <label>Opacity</label>
                <Slider
                  className={'w-100'}
                  buttons={true}
                  step={25}
                  min={0}
                  max={100}
                  value={opacityValue}
                  onChange={handleOpacityChange}>
                  {[0, 25, 50, 75, 100].map((perc, i) => (
                    <SliderLabel
                      key={i}
                      position={perc}
                      onClick={() => {
                        setOpacityValue(perc);
                      }}>
                      {perc.toString()}
                    </SliderLabel>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </FluidForm>
      <WindowActionsBar>
        <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => {
            if (isInputValid()) {
              const data = {
                isNew: editorEntity.isNew,
                entity
              };
              if (!data.entity.opacity) {
                data.entity.opacity = 100;
              }

              if (!data.entity.imageScale) {
                data.entity.imageScale = 100;
              }

              const entityResources = resources.map((item, index) => ({
                isNew: editorResource[index]?.isNew ?? false,
                entity: item
              })) as EntityEditor<ResourceEntity>[];

              const resource: ResourceEntity | undefined =
                state.gameDocument!.resources.find(
                  (item: ResourceEntity) => item.id === data.entity.imageResId
                );

              if (uploadedImage) {
                if (data.entity.imageResId) {
                  gameDocumentFiles.updateResourceFile(
                    data.entity.imageResId,
                    uploadedImage
                  );
                } else {
                  const resourceId = uuid();
                  data.entity.imageResId = resourceId;
                  AddResourceAsync(
                    state.gameDocument!,
                    resourceId,
                    uploadedImage.fileName ?? '',
                    'image',
                    uploadedImage.blobUrl,
                    resourceId,
                    uploadedImage.size
                  ).then((response) => {
                    setState((prev) => UpdateGameDocState(prev, response));
                  });
                }
              } else {
                if (resource) {
                  resource.value = imageUrl;
                  UpdateResourceAsync(
                    state.gameDocument!,
                    resource.id,
                    resource
                  ).then((response) => {
                    setState((prev) => UpdateGameDocState(prev, response));
                  });
                }
              }

              onSubmit(data);
              toggleDialog();
            }
          }}>
          Save
        </Button>
      </WindowActionsBar>
      {dialogUploadVisible && (
        <ResourceWindow
          onClose={() => {
            setDialogUploadVisible(false);
          }}
          onSubmit={updateMapResource}
          toggleDialog={() => setDialogUploadVisible(false)}
        />
      )}
    </Window>
  );
};
