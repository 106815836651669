import React, { useEffect, useState } from 'react';
import { AutoComplete, DropDownList } from '@progress/kendo-react-dropdowns';
import { GetLanguages } from '../../services/languages';
import { LanguageResponse } from '../../types/responses/language-response';
export const CustomFilterLanguage = (props: any) => {
  const operators = props.firstFilterProps.operators;

  const [languages, setLanguages] = useState<LanguageResponse[]>([]);

  const getLanguageList = async () => {
    const response = await GetLanguages();
    setLanguages(response);
  };

  const [state, setState] = React.useState({
    first: operators[0],
    second: operators[1]
  });

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;

    const { firstFilterProps } = props;
    const { secondFilterProps } = props;

    if (name === 'firstFilter') {
      firstFilterProps.onChange({
        value,
        operator: state.first.operator,
        syntheticEvent: event.syntheticEvent
      });
    }
    if (name === 'secondFilter') {
      secondFilterProps.onChange({
        value,
        operator: state.second.operator,
        syntheticEvent: event.syntheticEvent
      });
    }

    if (name === 'logic') {
      props.onLogicChange(event);
    }
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <div>
      <DropDownList
        data={operators}
        textField="text"
        value={state.first}
        onChange={(event) => setState({ ...state, first: event.target.value })}
      />
      <AutoComplete
        name="firstFilter"
        data={[...languages]}
        onChange={handleChange}
      />
    </div>
  );
};
