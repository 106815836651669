import { ComponentType, ReactNode } from 'react';
import { StandardInput } from '../../../components/forms';
import { AreaEntity } from '../../../types/game-document/';
import {
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import { AreaEditorFeature } from './area-editor-feature';
import { Algorithm } from '../../../types/algorithm';
import { AreaAlgorithmEvent } from '../../../types/game-document/area';
import RequiredFields from '../../../types/required-fields';

interface AreaEditorProps {
  editorMode: EditorMode;
  entity: AreaEntity;
  children?: ReactNode | undefined;
  requiredFields?: RequiredFields<AreaEntity>[];
  handleInputChange?: (event: InputChangeEvent | TextAreaChangeEvent) => void;
  handleAreaChange?: (
    key: string,
    value: string | Algorithm,
    type: 'entity' | 'algorithm'
  ) => void;
}

export const AreaEditor: ComponentType<AreaEditorProps> = ({
  editorMode,
  entity,
  handleInputChange = () => { },
  requiredFields,
  handleAreaChange = () => { },
  ...props
}: AreaEditorProps) => {
  return (
    <>
      {editorMode === 'basic' && (
        <div className={'d-flex flex-row'}>
          <div className={'flex-column flex-fill'}>
            <StandardInput
              name={'name'}
              label={'Name'}
              value={entity?.name}
              onChange={handleInputChange}
              validationMessage={
                requiredFields?.find((item) => item.name === 'name')
                  ?.errorMessage
              }
              autoFocus={true}
            />
            <StandardInput
              name={'description'}
              label={'Description'}
              value={entity?.description}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
      {editorMode === 'full' && (
        <AreaEditorFeature
          entity={entity}
          requiredFields={requiredFields}
          handleAlgorithmEventChange={(
            algorithm: Algorithm,
            type: AreaAlgorithmEvent
          ) => handleAreaChange(type, algorithm, 'algorithm')}
          handleInputChange={(e: InputChangeEvent | TextAreaChangeEvent) =>
            handleAreaChange(e.target.name!, e.target.value as string, 'entity')
          }
        />
      )}

      {props.children}
    </>
  );
};
