import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddResourceAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { TaskContentEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';
import {
  DeleteTaskContentAsync,
  UpdateTaskContentAsync
} from '../../../utils/game-document/assets/task-contents';
import {
  TaskContentEditor,
  TaskContentEditorWindow
} from '../../../features/game-document/task-contents';
import cloneDeep from 'lodash.clonedeep';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { uuid } from '../../../types/common-helper';

const TaskContent = () => {
  const { taskContentId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();
  const { gameId } = useParams();
  const { taskId } = useParams();

  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.taskContents?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<TaskContentEntity>(() =>
    getEntityById(taskContentId!)
  );
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TaskContentEntity>[]
  >([{ name: 'name', errorMessage: '' }]);

  const addPreMessageResource = (taskContent: TaskContentEntity) => {
    const preMessageGuid = uuid();
    AddResourceAsync(
      state.gameDocument!,
      'PreMessage',
      '',
      'text-md',
      '',
      preMessageGuid
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });

    UpdateTaskContentAsync(
      state.gameDocument!,
      taskContentId!,
      {
        ...entity,
        preMessageResId: preMessageGuid
      },
      false
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  useEffect(() => {
    let taskContent = state.gameDocument?.assets.taskContents?.find(
      (i) => i.id === taskContentId
    )!;
    /*
       if the task content does not yet have a preMessageResId, 
       it will be added to the task content and resources
    */
    if (taskContent && !taskContent.preMessageResId) {
      addPreMessageResource(taskContent);
    }
  }, []);

  useEffect(() => {
    setEntity(getEntityById(taskContentId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity?.name} - ${state.gameDocument?.name}`;
  }, [state, entity]);

  const handleEntityChange = (entityKey: string, value: string) => {
    setRequiredFields((prev) =>
      prev.map((taskContent: RequiredFields<TaskContentEntity>) => {
        const copyTaskContent = cloneDeep({ ...taskContent });
        copyTaskContent.errorMessage = '';
        if (copyTaskContent.name === (entityKey as keyof TaskContentEntity)) {
          const duplicateValueIndex: number =
            state.gameDocument?.assets.taskContents
              ?.filter((item: TaskContentEntity) => item.id !== entity.id)
              .findIndex(
                (item: TaskContentEntity) =>
                  item.name.toLowerCase() === value.toLowerCase()
              ) ?? -1;
          if (value === '') {
            copyTaskContent.errorMessage = ERR_INPUT_REQUIRED;
          } else if (duplicateValueIndex !== -1) {
            copyTaskContent.errorMessage = ERR_DUPLICATE_NAME_VALUE;
          }
        }
        return copyTaskContent;
      })
    );
    setEntity((prev) => ({
      ...prev,
      [entityKey]: value
    }));
    UpdateTaskContentAsync(
      state.gameDocument!,
      taskContentId!,
      {
        ...entity,
        [entityKey]: value
      },
      false
    ).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<TaskContentEntity>
  ) => {
    UpdateTaskContentAsync(
      state.gameDocument!,
      editorEntity.entity.id,
      editorEntity.entity
    ).then((updatedGameDocument) => {
      setState((state) => UpdateGameDocState(state, updatedGameDocument));
      setEntityEditorIsVisible(false);
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTaskContentAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        navigate('../');
      }
    );
  };

  return (
    <>
      <Toolbar title={entity?.name}>
        {taskId && (
          <Link
            to={`/designer/${gameId}/map/task/${taskId}`}
            className={
              'ml-0 k-button k-button-md k-button-solid k-button-solid-base k-rounded-md me-1'
            }>
            Back
          </Link>
        )}
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">delete</span>
        </Button>
        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">edit</span>
        </Button>
        {entityEditorIsVisible && (
          <TaskContentEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={{ isNew: false, entity: entity }}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <FluidForm>
        <TaskContentEditor
          editorMode={'full'}
          entity={entity}
          handleEntityChange={handleEntityChange}
          requiredFields={requiredFields}
        />
      </FluidForm>
    </>
  );
};

export default TaskContent;
