import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Switch } from '@progress/kendo-react-inputs';
import { Label, Error } from '@progress/kendo-react-labels';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import CountryComboBox from '../../components/combobox/country-combobox';
import UploadFileDialog from '../../components/dialogs/upload-file-dialog';
import { StandardInput, StandardTextarea } from '../../components/forms';
import Toolbar from '../../components/page-toolbar';
import UserPermission from '../../components/permission';
import PopupMenu, { popupMenu as MenuList } from '../../components/popupMenu';
import { BreadcrumbLocation, PrivatePage } from '../../components/private-page';
import { ResetPassword } from '../../features/profile/reset-password';
import { GetAllAsync } from '../../services/api';
import { PutUserRolesAsync } from '../../services/roles';
import {
  GetAllUserAddressByOrganisationAsync,
  PostUserAddressByOrganisationAsync,
  UpdateUserAddressByOrganisationAsync
} from '../../services/user-address';
import {
  GetAllUserPhoneNumbersByOrganisationAsync,
  PostUserPhoneNumberByOrganisationAsync,
  UpdateUserPhoneNumberByOrganisationAsync
} from '../../services/user-phone-number';
import {
  DeleteUserByOrganisationAsync,
  GetUserAsAdminAsync,
  PutUserByOrganisationAsync
} from '../../services/users';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { FileExtension } from '../../types/fileExtensions';
import { Media } from '../../types/media';
import { Organisation } from '../../types/organisation';
import { Permission, PermissionType } from '../../types/permission';
import { AddressResponse } from '../../types/responses/address-response';
import { PhoneNumberResponse } from '../../types/responses/phone-number-response';
import { Roles } from '../../types/roles';
import { AddUserRequest } from '../../types/user';
import CountryCodeCombobox from '../../components/combobox/country-code-combobox';
import { Autocomplete } from '../../components/auto-complete';
import { UserDepartmentResponse } from '../../types/responses/user-department';
import cloneDeep from 'lodash.clonedeep';
import {
  AdminGetUserProfileDepartmentsAsync,
  AdminPutUserProfileDepartmentsAsync,
  GetUserDepartmentsAsync
} from '../../services/user-department';
import { ResendRegistration } from '../../features/profile/resend-registration';
import { formatDateToDDMMYYYY } from '../../utils/date';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../../types/dialog-result';

export interface RequiredInput {
  id: string;
  name: string;
  errorMessage: string;
}

const AllUserPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useAuth();

  // parse id
  let parsedId = parseInt(id!);

  const [activeTab, setActiveTab] = useState('info');
  const [userPermission, setUserPermission] = useState<string[]>();
  const [organisationList, setOrganisationList] = useState<Organisation[]>([]);
  const [menuList, setMenuList] = React.useState<MenuList[]>([]);
  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [logoSrc, setLogoSrc] = useState<
    string | ArrayBuffer | null | undefined
  >();
  const [openDeleteUserDialog, setOpenDeleteUserDialog] =
    useState<boolean>(false);

  const departments = useRef<UserDepartmentResponse[]>();
  const [departmentData, setDepartmentData] = useState<
    UserDepartmentResponse[]
  >([]);
  const [userDepartments, setUserDepartments] = useState<
    UserDepartmentResponse[]
  >([]);

  const [userPhoneNumberRequest, setUserPhoneNumberRequest] =
    useState<PhoneNumberResponse>({
      type: 'Work',
      number: '',
      countryCode: '',
      countryIso: ''
    });
  const [userRequest, setUserRequest] = useState<AddUserRequest>({
    id: 0,
    userId: 0,
    status: 'Enabled',
    firstName: '',
    lastName: '',
    organisationId: 0,
    preferredName: '',
    avatarMediaId: null,
    gender: 'N',
    countryIso: '',
    countryName: '',
    emailAddress: {
      type: 'Primary',
      address: '',
      isPrimary: true
    },
    emailConfirmed: false
  });

  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    {
      id: 'firstName',
      name: 'First Name',
      errorMessage: ''
    },
    {
      id: 'lastName',
      name: 'Last Name',
      errorMessage: ''
    },
    {
      id: 'organisationId',
      name: 'Organisation',
      errorMessage: ''
    },
    {
      id: 'userName',
      name: 'Username',
      errorMessage: ''
    }
  ]);

  const [userAddressRequest, setUserAddressRequest] = useState<AddressResponse>(
    {
      id: 0,
      type: 'Primary',
      line1: '',
      countryIso: ''
    }
  );

  const handleSelect = (key: string) => {
    setActiveTab(key);
  };

  const emailCheckValid = (value: string) => {
    const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);

    return emailRegex.test(value) ? '' : 'Please enter a valid email.';
  };

  const addRequestValidation = () => {
    const newState = requiredInputs.map((obj) => {
      if (obj.id === 'userName') {
        if (userRequest?.emailAddress?.address === '') {
          return { ...obj, errorMessage: 'This field is required.' };
        } else {
          let emailError = emailCheckValid(userRequest?.emailAddress?.address!);
          if (emailError !== '') {
            return {
              ...obj,
              errorMessage: emailError
            };
          }
        }
      } else if (
        obj.id === 'organisationId' &&
        userRequest?.organisationId === undefined
      ) {
        return { ...obj, errorMessage: 'This field is required.' };
      } else if (
        obj.id === 'organisation' &&
        (userRequest?.organisationId === undefined ||
          userRequest?.organisationId === 0)
      ) {
        return { ...obj, errorMessage: 'This field is required.' };
      } else if (
        userRequest.hasOwnProperty(obj.id) &&
        userRequest[obj.id as keyof AddUserRequest] === ''
      ) {
        return { ...obj, errorMessage: 'This field is required.' };
      }
      return obj;
    });
    setRequiredInputs(newState);
    if (newState.filter((f) => f.errorMessage !== '').length > 0) {
      // if invalid stop all process
      return false;
    }
    return true;
  };

  const removeRequestValidation = (name: string) => {
    const updateRequestValidationState = requiredInputs.map((obj) => {
      if (obj.id === name) {
        return { ...obj, errorMessage: '' };
      }
      return obj;
    });
    setRequiredInputs(updateRequestValidationState);
  };

  const generateLogoMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];

    setMenuList(newMenus);
  };

  const onPermissionChange = useCallback(
    (permission: string, value: boolean) => {
      if (value) {
        setUserPermission((prev) => {
          let addedPermission =
            permission === Permission.Admin
              ? (auth.user?.profile.role as string[]).filter((o) =>
                  o.includes('Admin')
                )
              : [permission];
          if (
            permission.includes('Admin.') &&
            !prev?.includes(Permission.Admin)
          ) {
            addedPermission = [permission, 'Admin'];
          }
          return [
            ...(prev as PermissionType[]),
            ...(addedPermission as PermissionType[])
          ];
        });
      } else {
        setUserPermission((prev) =>
          prev?.filter((o) =>
            permission === Permission.Admin
              ? !o.includes('Admin')
              : o !== permission
          )
        );
      }
    },
    [auth.user?.profile.role]
  );

  const onSaveHandler = async () => {
    const userId = parseInt(id!);
    if (addRequestValidation()) {
      try {
        appStore.showLoading();
        await PutUserByOrganisationAsync(
          userRequest.organisationId!,
          userId,
          userRequest
        );
        // address request
        if (userAddressRequest?.id! > 0) {
          // patch
          await UpdateUserAddressByOrganisationAsync(
            userRequest.organisationId!,
            userId,
            userAddressRequest
          );
        } else if (
          userAddressRequest.line1 !== '' ||
          userAddressRequest.countryIso !== ''
        ) {
          await PostUserAddressByOrganisationAsync(
            userRequest.organisationId!,
            userId,
            userAddressRequest
          );
        }
        // Phone nummber request
        if (userPhoneNumberRequest?.id! > 0) {
          //patch phone number
          await UpdateUserPhoneNumberByOrganisationAsync(
            userRequest.organisationId!,
            userId as number,
            userPhoneNumberRequest!
          );
        } else if (userPhoneNumberRequest.number !== '') {
          // Post
          await PostUserPhoneNumberByOrganisationAsync(
            userRequest.organisationId!,
            userId as number,
            userPhoneNumberRequest
          );
        }
        await PutUserRolesAsync(
          userRequest.organisationId!,
          userRequest.id!,
          userPermission!
        );

        // Department update
        if (userDepartments) {
          const paramDepartments = userDepartments.map((i) => i.id!) ?? [];
          await AdminPutUserProfileDepartmentsAsync(
            userRequest.organisationId!,
            userId,
            paramDepartments
          );
        }

        toastStore.show(
          'Users',
          <div>Details and permissions updated.</div>,
          'success'
        );
        navigate('/administration/users');
      } catch (err) {
        console.error(err);
      } finally {
        appStore.hideLoading();
      }
    }
  };

  const handleMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setOpenFileDialog(true);
    } else if (menu.textMenu === 'Delete') {
      setUserRequest({ ...userRequest, avatarMediaId: null });
      setLogoSrc('');
    }
  };

  const changeOrganisationsHandler = (e: ComboBoxChangeEvent) => {
    setUserRequest({
      ...userRequest,
      organisationId: e.value?.id ?? ''
    });
  };

  const changeCountryHandler = (event: ComboBoxChangeEvent) => {
    setUserAddressRequest({
      ...userAddressRequest,
      countryIso: event.value?.iso ?? '',
      country: event.value?.country ?? ''
    });
  };

  const handleFilesSelected = (files?: Media[]) => {
    if (!files || files.length <= 0) {
      return;
    }
    const file = files[0];

    if (file.mediaId) {
      setUserRequest({
        ...userRequest,
        avatarMediaId: file.mediaId
      });
    }

    let fr = new FileReader();
    fr.readAsDataURL(file.file);
    fr.onload = function () {
      setLogoSrc(fr.result);
    };
    setOpenFileDialog(false);
  };

  const onChangeDepartmentEventHandler = (department: any) => {
    const exist = userDepartments.findIndex((x) => x.id === department.id);
    if (department && exist === -1) {
      let newUserDepartments = [...userDepartments];
      newUserDepartments.push({
        name: department.name ?? '',
        id: department.id ?? ''
      });
      setUserDepartments(newUserDepartments);
    }
  };

  const onDeleteDepartment = (department: UserDepartmentResponse) => {
    if (userDepartments) {
      const idx = userDepartments.indexOf(department);
      if (idx > -1 && userDepartments.length > 1) {
        let newData = cloneDeep(userDepartments);
        newData.splice(idx, 1);
        setUserDepartments(newData);
      } else if (userDepartments.length === 1) {
        setUserDepartments([]);
      }
    }
  };

  const fetchUserDepartments = async (orgId: number, userId: number) => {
    AdminGetUserProfileDepartmentsAsync(orgId, userId).then((response) => {
      if (response) {
        if (departments.current) {
          const data = departments.current.filter((department) =>
            response.data.includes(department.id!)
          );
          setUserDepartments(data);
        } else {
          setUserDepartments([]);
        }
      }
    });
  };

  const closeUploader = () => {
    setOpenFileDialog(false);
  };

  useEffect(() => {
    async function fetchUserDetails() {
      appStore.showLoading();
      try {
        const userProfileResponse = await GetUserAsAdminAsync(parseInt(id!));
        setUserRequest({
          ...userRequest,
          id: userProfileResponse?.id!,
          userId: userProfileResponse?.userId!,
          emailAddress: {
            address: userProfileResponse?.username!
          },
          organisationId: userProfileResponse?.organisationId!,
          preferredName: userProfileResponse?.preferredName!,
          status: userProfileResponse?.status!,
          firstName: userProfileResponse?.firstName!,
          lastName: userProfileResponse?.lastName!,
          avatarMediaId: userProfileResponse?.avatarMediaId!,
          emailConfirmed: userProfileResponse?.emailConfirmed,
          createdDateUtc: userProfileResponse?.createdDateUtc!
        });

        if (
          userProfileResponse.avatarMediaId !== null &&
          (userProfileResponse.avatarMediaId! as number) > 0
        ) {
          setLogoSrc(userProfileResponse.avatarMediaUrl!);
        }

        const allUserAdress = await GetAllUserAddressByOrganisationAsync(
          userProfileResponse?.organisationId!,
          parseInt(id!)
        );
        const mainAddress = allUserAdress?.data.find(
          (a) => a.type === 'Primary'
        );

        if (mainAddress) {
          setUserAddressRequest({
            ...userAddressRequest,
            id: mainAddress.id!,
            line1: mainAddress?.line1!,
            countryIso: mainAddress?.countryIso!,
            country: mainAddress?.country!
          });
        }

        const allUserPhoneNumber =
          await GetAllUserPhoneNumbersByOrganisationAsync(
            userProfileResponse?.organisationId!,
            parseInt(id!)
          );
        const workPhoneNumber = allUserPhoneNumber?.data.find(
          (p) => p.type === 'Work'
        );
        if (workPhoneNumber) {
          setUserPhoneNumberRequest({
            ...userPhoneNumberRequest,
            id: workPhoneNumber?.id!,
            number: workPhoneNumber?.number,
            countryCode: workPhoneNumber.countryCode
              ? `+${workPhoneNumber.countryCode}`
              : '',
            countryIso: workPhoneNumber.countryIso
          });
        }

        const data = await GetAllAsync(
          `organisations/${userProfileResponse?.organisationId!}/users/${id}/roles`
        );
        const permission = data.data as Roles[];
        setUserPermission(permission.map((o) => o.name));

        GetUserDepartmentsAsync().then((res) => {
          departments.current = res;
          setDepartmentData(departments.current);
          fetchUserDepartments(
            userProfileResponse?.organisationId!,
            parseInt(id!)
          );
        });
      } catch (err) {
        console.error(err);
      }
      appStore.hideLoading();
    }

    fetchUserDetails();
  }, []);

  const onBack = () => {
    navigate('/administration/users');
  };

  const confirmDeleteUserDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      let user = await GetUserAsAdminAsync(parseInt(id!));
      if (user) {
        await DeleteUserByOrganisationAsync(
          user.organisationId!,
          parseInt(id!)
        );
      }
      onBack();
    }
    closeDeleteUserDialog();
  };

  const showDeleteUserDialog = () => {
    setOpenDeleteUserDialog(true);
  };
  const closeDeleteUserDialog = () => {
    setOpenDeleteUserDialog(false);
  };

  useEffect(() => {
    async function getOrganisations() {
      try {
        const organisations = await GetAllAsync('organisations');
        setOrganisationList(organisations.data as Organisation[]);
      } catch (err) {
        console.error(err);
      }
    }
    generateLogoMenu();
    getOrganisations();
  }, []);

  const breadcrumbs: BreadcrumbLocation[] = [
    { label: 'Dashboard', href: '/' },
    { label: 'Administration', href: '../administration' },
    { label: 'All users', href: '../administration/users' }
  ];

  return (
    <PrivatePage breadcrumb={breadcrumbs} pageTitle={'Administration'}>
      <>
        <Toolbar title={`${userRequest.firstName} ${userRequest.lastName}`}>
          <Button className={'mr-5'} themeColor={'secondary'} onClick={onBack}>
            Back
          </Button>
          <Button themeColor={'primary'} onClick={onSaveHandler}>
            Update
          </Button>
        </Toolbar>
        <hr />
        <Row className={'h-60 w-full overflow-auto'}>
          <Col md={'12'}>
            <Tabs
              defaultActiveKey={'info'}
              id={'timer-tabs'}
              className={'mb-3'}
              onSelect={(key) => handleSelect(key as string)}>
              <Tab eventKey={'info'} title={'Info'}>
                <Row>
                  <Col xs={2}>
                    <div className={'d-flex'}>
                      {logoSrc && (
                        <div
                          onClick={() => setOpenFileDialog(true)}
                          className={'round-card rounded-full h-9 w-9'}
                          style={{
                            backgroundImage: `url('${logoSrc}')`
                          }}></div>
                      )}
                      {!logoSrc && (
                        <div
                          onClick={() => setOpenFileDialog(true)}
                          className={
                            'rounded-circle bg-primary d-flex justify-content-center align-items-center h-9 w-9'
                          }>
                          <i
                            className={'bi bi-person text-white'}
                            style={{ fontSize: '5rem' }}
                          />
                        </div>
                      )}

                      <div>
                        <PopupMenu
                          id={userRequest?.avatarMediaId ?? 0}
                          menus={menuList}
                          onMenuSelected={handleMenuSelected}></PopupMenu>
                      </div>
                    </div>
                  </Col>
                  <Col xs={8}>
                    <div className={'mb-3'}>Information</div>
                    <div className={'card p-4 border-0'}>
                      <Row className={'p-1'}>
                        <Col md={4}>
                          <StandardInput
                            label={'First Name'}
                            name={'First Name'}
                            value={userRequest?.firstName}
                            onChange={(e) => {
                              setUserRequest({
                                ...userRequest,
                                firstName: e.value
                              });
                              if (e.value !== '') {
                                removeRequestValidation('firstName');
                              }
                            }}
                            validationMessage={
                              requiredInputs?.find(
                                (item) => item.name === 'First Name'
                              )?.errorMessage
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <StandardInput
                            label={'Last Name'}
                            name={'Last Name'}
                            value={userRequest?.lastName}
                            onChange={(e) => {
                              setUserRequest({
                                ...userRequest,
                                lastName: e.value
                              });
                              if (e.value !== '') {
                                removeRequestValidation('lastName');
                              }
                            }}
                            validationMessage={
                              requiredInputs?.find(
                                (item) => item.name === 'Last Name'
                              )?.errorMessage
                            }
                          />
                        </Col>
                      </Row>
                      <Row className={'p-1'}>
                        <Col md={8}>
                          <StandardInput
                            label={'Username (email)'}
                            name={'Username'}
                            disabled={true}
                            value={userRequest?.emailAddress?.address ?? ''}
                            onChange={(e) => {
                              setUserRequest((currentRequest) => {
                                return {
                                  ...currentRequest,
                                  emailAddress: {
                                    ...currentRequest.emailAddress,
                                    address: e.value
                                  }
                                };
                              });

                              if (e.value !== '') {
                                removeRequestValidation('userName');
                              }
                            }}
                            validationMessage={
                              requiredInputs?.find(
                                (item) => item.name === 'Username'
                              )?.errorMessage
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <Label>Organisation</Label>
                          <ComboBox
                            data={organisationList}
                            textField={'name'}
                            filterable={true}
                            value={organisationList?.find(
                              (o) => o.id === userRequest?.organisationId
                            )}
                            clearButton={false}
                            onChange={(e) => {
                              changeOrganisationsHandler(e);
                            }}
                          />
                          {requiredInputs?.find(
                            (item) => item.name === 'Organisation'
                          )?.errorMessage && (
                            <Error>
                              {
                                requiredInputs?.find(
                                  (item) => item.name === 'Organisation'
                                )?.errorMessage
                              }
                            </Error>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className={'mt-4'}>Optional Information</div>
                    <div className={'card p-4'}>
                      <Row className={'p-1'}>
                        <Col md={4}>
                          <StandardInput
                            label={'Nickname'}
                            name={'Nickname'}
                            value={userRequest?.preferredName}
                            onChange={(e) => {
                              setUserRequest({
                                ...userRequest,
                                preferredName: e.value
                              });
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <Label>Country</Label>
                          <CountryComboBox
                            value={{
                              iso: userAddressRequest?.countryIso,
                              country: userAddressRequest?.country
                            }}
                            clearButton={false}
                            onChange={changeCountryHandler}
                          />
                        </Col>
                      </Row>
                      <Row className={'p-1'}>
                        <Col md={4}>
                          <Label>Phone Number</Label>
                          <div className="d-flex flex-column gap-2">
                            <CountryCodeCombobox
                              value={userPhoneNumberRequest?.countryCode ?? ''}
                              columns={[
                                {
                                  field: 'code',
                                  header: 'Code',
                                  width: '100px'
                                },
                                {
                                  field: 'countryIso',
                                  header: 'Iso',
                                  width: '50px'
                                },
                                {
                                  field: 'country',
                                  header: 'Country'
                                }
                              ]}
                              onChange={(e) => {
                                setUserPhoneNumberRequest({
                                  ...userPhoneNumberRequest,
                                  countryIso: e.value.countryIso,
                                  countryCode: e.value.code
                                });
                              }}
                            />

                            <StandardInput
                              name={'Phone Number'}
                              value={userPhoneNumberRequest?.number ?? ''}
                              onChange={(e) => {
                                setUserPhoneNumberRequest({
                                  ...userPhoneNumberRequest,
                                  number: e.value
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <StandardTextarea
                            label="Address"
                            name="Address"
                            style={{
                              height: '83px'
                            }}
                            value={userAddressRequest?.line1 ?? ''}
                            onChange={(e) => {
                              setUserAddressRequest({
                                ...userAddressRequest,
                                line1: e.value
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label>Department</Label>
                          <Autocomplete<UserDepartmentResponse>
                            className={'d-flex justify-content-start w-100'}
                            items={departmentData ?? []}
                            textField="name"
                            onChange={
                              onChangeDepartmentEventHandler
                            }></Autocomplete>
                        </Col>
                        <Col md={8} className={'mt-2'}>
                          <ul className="list-group list-group-flush">
                            {userDepartments &&
                              userDepartments.map((department, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="list-group-item d-flex justify-content-between">
                                    <span className={'text-primary'}>
                                      {department?.name ?? ''}
                                    </span>
                                    <span
                                      className={
                                        'material-symbols-outlined text-danger'
                                      }
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        onDeleteDepartment(department);
                                      }}>
                                      close
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className={'d-flex align-items-center mt-3'}>
                        <Col md={4}>
                          <div className={'mt-3'}>
                            <Label>Status</Label>
                          </div>
                          <div className={'text-primary'}>
                            <Switch
                              name={'Status'}
                              onChange={(e) => {
                                setUserRequest({
                                  ...userRequest,
                                  status: e.value ? 'Enabled' : 'Disabled'
                                });
                              }}
                              checked={
                                userRequest?.status === 'Enabled' ? true : false
                              }
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          {userRequest?.status === 'Enabled' && (
                            <ResetPassword
                              userId={parsedId}
                              title={'Password reset'}
                              buttonText={'Send Reset Password Link To User'}
                              organisationId={userRequest.organisationId!}
                            />
                          )}
                        </Col>
                        <Col md={4}>
                          <ResendRegistration
                            userId={parsedId}
                            emailConfirmed={userRequest.emailConfirmed}
                            buttonText={'Resend Registration Email to User'}
                            organisationId={userRequest.organisationId!}
                          />
                        </Col>
                      </Row>
                      <Row className={'mt-3'}>
                        <Col>
                          <div className={'text-muted'}>
                            <div>
                              Date Created: {}
                              {formatDateToDDMMYYYY(
                                userRequest?.createdDateUtc!
                              )}{' '}
                            </div>
                            {/* <div>Recent Login: 02/02/2024 - 13:00:34</div> */}
                          </div>
                          <span
                            className={'link-primary'}
                            onClick={showDeleteUserDialog}>
                            Remove
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey={'permissions'} title={'Permissions'}>
                {activeTab === 'permissions' && (
                  <UserPermission
                    permissions={auth.user?.profile.role as PermissionType[]}
                    userPermissions={userPermission as PermissionType[]}
                    onChange={onPermissionChange}
                    showAdminPermission={true}
                  />
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </>
      {openDeleteUserDialog && (
        <YesNoDialog
          title="Remove User"
          onConfirm={confirmDeleteUserDialog}
          onClose={closeDeleteUserDialog}>
          Are you sure you want to remove this user?
        </YesNoDialog>
      )}
      {openFileDialog && (
        <UploadFileDialog
          isMultiple={false}
          isVisibleDropFile={true}
          isVisibleUploadPhoto={true}
          isVisibleTakePhoto={false}
          isDirectUpload={true}
          maxImageSize={1}
          allowedExtension={[FileExtension.JPG, FileExtension.PNG]}
          onFilesSelected={handleFilesSelected}
          onConfirm={() => {
            setOpenFileDialog(false);
          }}
          onClose={closeUploader}
          isIcon={true}
          title={'Upload user profile'}
        />
      )}
    </PrivatePage>
  );
};

export default AllUserPage;
