export const Designer = {
  allTasksCompletion: 'showAllTasksCompletion'
};

export const PreGame = {
  name: 'showPreGameName',
  avatar: 'showPreGameAvatar',
  lobby: 'showPreGameLobby',
  teams: 'showPreGameTeams',
  createTeams: 'showPreGameCreateTeams',
  joinLeaveTeams: 'showPreGameJoinLeaveTeams',
  inventory: 'showPreGameInventory',
  roles: 'showPreGameRoles',
  chats: 'showPreGameChat',
  teamRequired: 'isPreGameTeamRequired'
};

export const InGame = {
  information: 'showInGameInfo',
  playerTeamMapPositions: 'showInGamePlayerTeamMapPositions',
  teamChat: 'showInGameTeamChat',
  allPlayerChat: 'showInGameAllPlayerChat',
  leaderboard: 'showInGameLeaderboard',
  scoreboard: 'showInGameScoreboard',
  playerTeamScore: 'showInGamePlayerTeamScore',
  tasksRequireProximity: 'tasksRequireProximity',
  setTaskProximity: 'setTaskProximity',
  showTasknameVisibility: 'showTasknameVisibility',
  showTeamOrPlayerNameOnMap: 'showTeamOrPlayerNameOnMap',
  showPlayerScoreOnMap: 'showPlayerScoreOnMap',
  showPlayerDetailByClickOnMap: 'showPlayerDetailByClickOnMap'
};
