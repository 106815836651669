import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { appStore } from '../stores/app-store';
import { ColumnMenu } from '../components/columnMenu';
import { useNavigate } from 'react-router-dom';
import { ActionsMylibrary } from './command-grid/command-grid-mylibrary';
import { CustomFilterLanguage } from './filter/custom-filter-language';
import { toastStore } from '../stores/toast-store';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { MyLibraryCardView } from './mylibrary-cardview';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import {
  CopyGameAsync,
  DeleteGameAsync,
  GetGamesAsync
} from '../services/games';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../types/dialog-result';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const MylibraryGrid = () => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [mylibraries, setMylibraries] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchMylibraries = async () => {
    try {
      appStore.showLoading();
      const response = await GetGamesAsync(
        `${toDataSourceRequestString(dataState)}`
      );
      setMylibraries(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchMylibraries();
  }, [dataState]);

  const handleTitleClick = (id: number) => {
    navigate(`../designer/${+id.toString()}`);
  };

  const onColumnTitleClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer p-0 text-start'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const handleCopyLibrary = async (id: number) => {
    try {
      appStore.showLoading();

      await CopyGameAsync(id, false);

      toastStore.show(
        'My library',
        <div>Game copied to My Library.</div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show(
        'My library',
        <div>Failed to copy to My Library.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      fetchMylibraries();
    }
  };

  const CommandCell = (props: GridCellProps) => (
    <ActionsMylibrary
      onCopy={handleCopyLibrary}
      onView={onViewLibrary}
      onEdit={onEditLibrary}
      onDelete={onDelete}
      {...props}></ActionsMylibrary>
  );

  const StatusColoring = (props: GridCellProps) => {
    if (
      props.dataItem.status === 'New' ||
      props.dataItem.status === 'Rejected'
    ) {
      return (
        <td>
          <span className={'text-danger'}>Not approved</span>
        </td>
      );
    } else {
      return (
        <td>
          <span
            className={
              props?.dataItem?.status === 'Pending'
                ? 'text-warning'
                : 'text-success'
            }>
            {props.dataItem.status}
          </span>
        </td>
      );
    }
  };

  const StatusName = (props: GridCellProps) => {
    if (props.dataItem.status === 'Approved') {
      return (
        <td>
          <span>Published/Global Library</span>
        </td>
      );
    } else if (props.dataItem.status === 'New') {
      return (
        <td>
          <span>Draft</span>
        </td>
      );
    } else if (['Published', 'Rejected'].includes(props.dataItem.status)) {
      return (
        <td>
          <span>Published</span>
        </td>
      );
    } else if (props.dataItem.status === 'Pending') {
      return (
        <td>
          <span>Published/Submitted</span>
        </td>
      );
    } else if (props.dataItem.status === 'Approved') {
      return (
        <td>
          <span>Published/Global Library</span>
        </td>
      );
    } else {
      return (
        <td>
          <span>{props.dataItem.status}</span>
        </td>
      );
    }
  };

  const onAddLibrary = () => {
    navigate('../mylibrary/games/new');
  };

  const onViewLibrary = (id: number) => {
    navigate('../global-games/' + id.toString() + '?page=mylibrary');
  };

  const onEditLibrary = (id: number) => {
    navigate(`../designer/${+id.toString()}`);
  };

  const onDelete = (id: number) => {
    onDeleteLibrary(id);
  };

  const onDeleteLibrary = async (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        await DeleteGameAsync(selectedId);
        fetchMylibraries();

        toastStore.show('My Library', <div>Game deleted.</div>, 'success');
      } catch {
        toastStore.show(
          'My Library',
          <div>Failed to delete game.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}</td>;
  };

  const CellEllipsisAndTooltip = (props: GridCellProps) => {
    return (
      <td title={props.dataItem.languages}>
        <div className={'grid-ellipsis-cell'}>{props.dataItem.languages}</div>
      </td>
    );
  };

  return (
    <>
      <Toolbar title={'Games'}>
        <HelpSupport
          title="My Library - Games"
          url="https://forum.catalystglobal.com/t/my-library-games"
        />
        <Button onClick={onAddLibrary} themeColor={'primary'}>
          Add new
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search assets'}
          columnsToSearch={['name', 'languages', 'status', 'source']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={mylibraries.data}
          ref={gridExportRef}
          fileName={`mylibraries.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-mylibrary'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={mylibraries}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'name'}
                title={'Name'}
                columnMenu={ColumnMenu}
                cell={onColumnTitleClick}
              />
              <Column
                field={'createdDateUtc'}
                title={'Created'}
                cell={DateCell}
              />
              <Column
                field={'source'}
                title={'Source'}
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <Column title={'Status'} cell={StatusName} />
              <Column
                field={'languages'}
                title={'Languages'}
                cell={CellEllipsisAndTooltip}
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter
                    {...props}
                    filterUI={CustomFilterLanguage}
                  />
                )}
              />
              <Column title={'Actions'} cell={CommandCell} width={210} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'card' && (
            <MyLibraryCardView
              data={mylibraries.data}
              onTitlelick={handleTitleClick}
              onCopy={handleCopyLibrary}
              onView={handleTitleClick}
              onEdit={onEditLibrary}
              onDelete={onDeleteLibrary}
              total={mylibraries.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>

        {showDeleteConfirm && (
          <YesNoDialog
            title={'Remove Library'}
            onConfirm={onConfirmDelete}
            onClose={closeDeleteDialog}>
            Are you sure you want to delete this game?
          </YesNoDialog>
        )}
      </div>
    </>
  );
};
export default MylibraryGrid;
