import { GameDocument } from '../../../types/game-document';
import { InGameSettings } from '../../../types/game-document/settings';
import { uuid } from '../../../types/common-helper';
import { MergeGameSettings } from './index';

/**
 * Adds a new In Game Setting to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new In Game Setting
 * @param description - The Description for the new In Game Setting
 * @returns The updated Game Document
 */
export const AddInGameSettingAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string
) => {
  let inGameSetting = {
    id: uuid(),
    name: name,
    description: description,
    showInGameInfo: false,
    showInGamePlayerTeamMapPositions: false,
    showInGameTeamChat: false,
    showInGameAllPlayerChat: false,
    showInGameLeaderboard: false,
    showInGameScoreboard: false,
    showInGamePlayerTeamScore: false,
    tasksRequireProximity: false,
    showTasknameVisibility: false
  };
  return MergeGameSettings(gameDocument, inGameSetting, 'inGame');
};

/**
 * Updates the identified Setting in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param inGameSetting - The new in game settings
 * @constructor
 */
export const UpdateInGameSettingAsync = async (
  gameDocument: GameDocument,
  inGameSetting: InGameSettings
) => {
  return MergeGameSettings(gameDocument, inGameSetting, 'inGame');
};
